import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Loader from "./Loader";
import { getFacilityInfo } from "../redux/authSlice";

function InventoryShipInfo({ facilityId }: { facilityId: any }): ReactElement {
  const dispatch = useDispatch();
  const [facilityInfo, setFacilityInfo] = useState<any>("");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const fetchStore = async () => {
      if (localStorage.getItem("loggedInToken")) {
        if (facilityId && facilityId !== "None") {
          setIsLoading(true);
          await dispatch(getFacilityInfo({ setFacilityInfo, facilityId }));
          setIsLoading(false);
        }
      }
    };
    fetchStore();
  }, [dispatch, facilityId]); // eslint-disable-line

  return (
    <div className="p-8 w-full h-auto rounded-3xl shadow-sm bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <h1 className="font-bold mb-2">Ship to Information</h1>
        {Object.keys(facilityInfo).length > 0 && (
          <div>
            <p>{facilityInfo.facilityName ? facilityInfo.facilityName : ""}</p>
            <p>
              {facilityInfo.contactInfo &&
              facilityInfo.contactInfo.postalAddress &&
              facilityInfo.contactInfo.postalAddress.attnName
                ? `Attn: ${facilityInfo.contactInfo.postalAddress.attnName}`
                : ""}
            </p>
            <p>{facilityInfo.address1 ? facilityInfo.address1 : ""}</p>
            <p>{facilityInfo?.address2 ? facilityInfo?.address2 : ""}</p>
            <div className="flex flex-wrap mb-3 items-center">
              <p>{facilityInfo.city ? facilityInfo.city : ""}</p>
              <p>
                {facilityInfo.contactInfo &&
                facilityInfo.contactInfo.postalAddressStateGeo &&
                facilityInfo.contactInfo.postalAddressStateGeo.geoCodeAlpha2
                  ? `, ${facilityInfo.contactInfo.postalAddressStateGeo.geoCodeAlpha2}`
                  : ""}
              </p>
              <p>
                {facilityInfo.contactInfo &&
                facilityInfo.contactInfo.postalAddressCountryGeo &&
                facilityInfo.contactInfo.postalAddressCountryGeo.geoCodeAlpha2
                  ? `, ${facilityInfo.contactInfo.postalAddressCountryGeo.geoCodeAlpha2}`
                  : ""}
              </p>
              <p>
                {facilityInfo.postalCode ? `, ${facilityInfo.postalCode}` : ""}
              </p>
            </div>
            <p>
              {facilityInfo.countryCode && `(${facilityInfo.countryCode})`}{" "}
              {facilityInfo.areaCode && facilityInfo.areaCode}{" "}
              {facilityInfo.contactNumber && `- ${facilityInfo.contactNumber}`}
            </p>
          </div>
        )}
      </Loader>
    </div>
  );
}

export default InventoryShipInfo;
