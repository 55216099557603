import React, { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import CreateOrder from "../components/CreateOrder";
import Sidebar from "../components/Sidebar";

function CreateOrderScreen(props: any): ReactElement {
  let isTestOrder = false;
  if (
    props &&
    props.location &&
    props.location.state &&
    props.location.state.isTestOrder
  ) {
    isTestOrder = props.location.state.isTestOrder;
  }
  const parsed = queryString.parse(props.location.search);
  if (parsed.isTestOrder) {
    if (parsed.isTestOrder === "true") {
      isTestOrder = true;
    } else if (parsed.isTestOrder === "false") {
      isTestOrder = false;
    }
  }
  const currentScreen = "Create Order";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            Create an Order
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary ml-1"
              onClick={() => {
                history.push("/createOrder");
              }}
            >
              {currentScreen}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-0 lg:grid-cols-3">
          <CreateOrder isTestOrder={isTestOrder} />
          {/* <div>
              <InventoryShipInfo setIsLoading={setIsLoading} />
            </div> */}
        </div>
      </div>
    </Sidebar>
  );
}

export default CreateOrderScreen;
