import React, { useEffect, ReactElement, useState } from "react";
import { Form, FormInstance } from "antd";
import { useDispatch } from "react-redux";

import { getUserInfo, updateAccountDetails } from "../redux/authSlice";
import Button from "./Button";
import Input from "./Input";
import Loader from "./Loader";

type AccountDetailsData = {
  userFullName: string;
  emailAddress: string;
  phoneNumber: string;
};

function AccountDetails(): ReactElement {
  const form = React.createRef<FormInstance>();
  const [mapForm] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  let userInfo: any = localStorage.getItem("setUserInfo");
  if (!userInfo) {
    userInfo = {};
  } else {
    userInfo = JSON.parse(userInfo);
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      setIsLoading(true);
      if (localStorage.getItem("loggedInToken")) {
        await dispatch(getUserInfo());
      }
      setIsLoading(false);
    };
    fetchUserInfo();
  }, [dispatch]);

  useEffect(() => form.current?.resetFields(), [userInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="px-5 pt-6 pb-1 mr-9 mb-9 w-full sm:w-96 rounded-2xl shadow-sm bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <h1 className="font-bold text-base mb-7">Account Details</h1>
        <div className="w-11/12 mx-auto">
          <Form
            ref={form}
            form={mapForm}
            name="account_details"
            className="flex flex-col justify-between"
            initialValues={userInfo}
            onFinish={async (values: any) => {
              setIsLoading(true);
              console.log(JSON.stringify(values));
              const data: AccountDetailsData = {
                userFullName: values.name,
                emailAddress: values.email,
                phoneNumber: values.phone,
              };
              await dispatch(updateAccountDetails(data));
              await dispatch(getUserInfo());
              setIsLoading(false);
            }}
          >
            <div>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Name is required!",
                  },
                ]}
              >
                <Input
                  title="Name"
                  placeholder="First Last"
                  showDot={true}
                  //   isDisabled={accountType === "owner" ? false : true}
                />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required",
                  },
                  {
                    type: "email",
                    message: "Email should be a valid one!",
                  },
                ]}
              >
                <Input
                  title="Email"
                  placeholder="yourname@email.com"
                  showDot={true}
                  //   isDisabled={accountType === "owner" ? false : true}
                />
              </Form.Item>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Phone Number is required!",
                  },
                  {
                    pattern:
                      /^(\+?\d{1,3}[- .]?( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4,7}([xX]{1}(\d{1,4}))?$/,
                    message: "Please enter a valid Phone number",
                  },
                ]}
              >
                <Input
                  title="Phone Number"
                  placeholder="XXX-XXX-XXXX"
                  showDot={true}
                  //   isDisabled={accountType === "owner" ? false : true}
                />
              </Form.Item>
              <Form.Item name="required" style={{ marginBottom: "5px" }}>
                <div className="flex flex-row">
                  <div className="w-1.5 h-1.5 rounded-full mt-2 mr-2 bg-primary"></div>
                  <div className="text-sm">Required</div>
                </div>
              </Form.Item>
            </div>
            <div>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="flex justify-end">
                    <Button
                      widthFull
                      buttonText="Save Changes"
                      isDisabled={
                        mapForm.isFieldsTouched(true) ||
                        !!mapForm
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    />
                  </div>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default AccountDetails;
