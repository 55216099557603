import React, { useState } from "react";
import { Form } from "antd";
import { Link, useHistory } from "react-router-dom";
import { Image } from "antd";
import { useDispatch } from "react-redux";

import Button from "./Button";
import Loader from "./Loader";
import Input from "./Input";
import Footer from "./Footer";
import logo from "../images/logo.svg";
import { getSessionToken, loginUser } from "../redux/authSlice";

type LogInData = {
  email: string;
  password: string;
  history: any;
};

function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-lg bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="w-max mx-auto">
          <Image src={logo} width={250} preview={false} />
        </div>
        <div className="w-11/12 sm:w-8/12 mx-auto mt-5">
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              const data: LogInData = {
                email: values.email,
                password: values.password,
                history,
              };
              await dispatch(getSessionToken());
              await dispatch(loginUser(data));
              setIsLoading(false);
            }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  //type: 'email',
                  message: "Username is required",
                },
              ]}
            >
              <Input title="Username" placeholder="username" showDot={true} />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: "Password is required",
                },
              ]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item>
              <div className="mx-auto">
                <Button widthFull buttonText="Log In" />
              </div>
            </Form.Item>
          </Form>
          <div className="mx-auto mb-5 flex flex-wrap justify-center items-center">
            <div>
              <p className="font-light mr-1">Forgot your password?</p>
            </div>
            <div>
              <Link
                to="/resetPassword"
                className="border-b text-primary border-primary hover:text-primary"
              >
                Reset Password
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </Loader>
    </div>
  );
}

export default Login;
