import React, { useEffect, ReactElement, useState } from "react";
import { Form, FormInstance, Image } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../components/Button";
import Dropdown from "../components/Dropdown";
import Input from "../components/Input";
import logo from "../images/logo.svg";
import Loader from "./Loader";
import {
  updatePassword,
  userSelector,
  clearState,
  getFpAccount,
  industryTypeList,
} from "../redux/authSlice";
import Footer from "../components/Footer";

type SetPasswordData = {
  emailAddress: string;
  username: any;
  userId: any;
  password: string;
  oldPassword: string;
  passwordVerify: string;
  isReset: boolean;
  history: any;
};

function SetPassword({
  email,
  oldPassword,
  accountId,
}: {
  email: any;
  oldPassword: any;
  accountId: any;
}): ReactElement {
  const form = React.createRef<FormInstance>();
  const [fulfillmentInfo, setFulfillmentInfo] = useState({
    name: "",
    userId: "",
    email: "",
    phone: "",
    storeName: "",
    industry: "",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isSuccess, industryTypes } = useSelector(userSelector); // eslint-disable-line
  const validatePassword = (rule: any, value: any, callback: any) => {
    const res =
      /^(?=.*[A-Za-z_])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z_\d@$!%*#?&]{8,}$/.test(
        value
      );
    if (!res) {
      callback(
        "Password must be at least 8 characters long, contain an alphabet, a number and a special character!"
      );
    } else {
      callback();
    }
  };
  useEffect(() => {
    const isAuth = localStorage.getItem("loggedInToken");
    if (isAuth) {
      history.push("/");
    }
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]);

  useEffect(() => {
    const getFulfillmentInfo = async () => {
      await dispatch(getFpAccount({ email, accountId, setFulfillmentInfo }));
      await dispatch(industryTypeList());
    };
    getFulfillmentInfo();
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isSuccess, dispatch]); // eslint-disable-line
  useEffect(() => form.current?.resetFields(), [fulfillmentInfo]); // eslint-disable-line

  console.log("---------fulfillmentInfo", fulfillmentInfo);

  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-sm bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="w-max mx-auto">
          <Image
            src={logo}
            width={250}
            preview={false}
            onClick={() => {
              history.push("/");
            }}
          />
        </div>
        <div className="w-full sm:w-9/12 mx-auto mt-3">
          <h1 className="text-xl font-medium text-black mb-3 mx-auto text-center">
            Welcome to Fulfillment Plus
            <span className="text-primary-light">!</span>
          </h1>
          <h1 className="mb-4 text-center">Please set up your account</h1>
        </div>
        <div className="w-full sm:w-8/12 mx-auto mb-3">
          <Form
            ref={form}
            name="set_password"
            initialValues={{
              fulfillmentInfo,
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              const data: SetPasswordData = {
                emailAddress: values.email,
                oldPassword,
                userId: values.userId,
                username: values.username,
                password: values.password,
                passwordVerify: values.passwordVerify,
                isReset: false,
                history,
              };
              await dispatch(updatePassword(data));
              setIsLoading(false);
            }}
          >
            <Form.Item
              initialValue={fulfillmentInfo.name}
              name="name"
              rules={[
                {
                  required: false,
                  message: "Name is required!",
                },
              ]}
            >
              <Input
                isDisabled={true}
                title="Name"
                placeholder="First Last"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              initialValue={fulfillmentInfo.userId}
              name="userId"
              className="hidden"
            >
              <Input
                isDisabled={true}
                title="Name"
                placeholder="First Last"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              initialValue={fulfillmentInfo.email}
              name="email"
              rules={[
                {
                  required: false,
                  message: "Email is required",
                },
                // {
                //   type: "email",
                //   message: "Email should be a valid one!",
                // },
              ]}
            >
              <Input
                isDisabled={true}
                title="Email"
                placeholder="yourname@email.com"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              initialValue={fulfillmentInfo.phone}
              name="phone"
              rules={[
                {
                  required: false,
                  message: "Phone Number is required!",
                },
                // {
                //   pattern:
                //     /^(\+?\d{1,3}[- .]?( )?)?((\(\d{3}\))|\d{3})[- .]?\d{3}[- .]?\d{4,7}([xX]{1}(\d{1,4}))?$/,
                //   message: "Please enter a valid Phone number",
                // },
              ]}
            >
              <Input
                isDisabled={true}
                title="Phone Number"
                placeholder="XXX-XXX-XXXX"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              initialValue={fulfillmentInfo.storeName}
              name="storeName"
              rules={[
                {
                  required: false,
                  message: "Store Name is required!",
                },
                // {
                //    pattern: /^[a-zA-Z0-9][a-zA-Z0-9-]*$/,
                //   message: "Please enter valid Store Name",
                // },
              ]}
            >
              <Input
                isDisabled={true}
                title="Store Name"
                placeholder="Store Name"
                suffix=""
              />
            </Form.Item>
            {fulfillmentInfo.industry && (
              <Form.Item
                initialValue={fulfillmentInfo.industry}
                name="industry"
              >
                <Dropdown
                  isDisabled={true}
                  data={industryTypes}
                  placeholder="Select one"
                  title="Industry / Vertical"
                />
              </Form.Item>
            )}
            <div className="border-b border-gray-400 my-5"></div>
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please Enter Your User Name",
                },
              ]}
            >
              <Input
                isPassword={true}
                title="User Name"
                placeholder="User Name"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ validator: validatePassword }]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              name="passwordVerify"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Repeat Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item>
              <div className="mx-auto">
                <Button widthFull buttonText="Submit" />
              </div>
            </Form.Item>
          </Form>
        </div>
        <Footer />
      </Loader>
    </div>
  );
}

export default SetPassword;
