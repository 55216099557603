import React, { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import ImportProduct from "../components/ImportProduct";
import ImportListing from "../components/ImportListing";
import CountDown from "../components/Countdown";

function ProductImportScreen(): ReactElement {
  const [shouldRerender, setShouldRerender] = useState(false);
  const currentScreen = "Import Product";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            Import Product
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary ml-1"
              onClick={() => {
                history.push("/importProduct");
              }}
            >
              {currentScreen}
            </h1>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-0 lg:grid-cols-3">
          <ImportProduct setShouldRerender={setShouldRerender} />
        </div>
        <div className="mt-5">
          <CountDown setShouldRerender={setShouldRerender} />
        </div>
        <div className="mt-5">
          <ImportListing
            importType="Product_Data_Import,Products_Data_Import_Template"
            setShouldRerender={setShouldRerender}
            shouldRerender={shouldRerender}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default ProductImportScreen;
