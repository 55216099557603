/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Image, Popover } from "antd";
import { SwapOutlined } from "@ant-design/icons";
import {
  AiOutlineLayout,
  AiOutlineMenuFold,
  AiOutlineMenuUnfold,
  AiOutlineProfile,
  AiOutlineSetting,
} from "react-icons/ai";
import { ImCodepen } from "react-icons/im";
import { FaReceipt } from "react-icons/fa";
import { BiImport } from "react-icons/bi";
import { MdInventory2 } from "react-icons/md";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";

import {
  userSelector,
  clearState,
  logout,
  toggleSidebar,
  getActiveOrg,
  getStoreList,
  setActiveStore,
} from "../redux/authSlice";
import logo from "../images/logo.svg";
import logo2 from "../images/logo2.svg";
import logout_svg from "../images/logout.svg";
import { setChatbotParams } from "../helper/function";

function Sidebar({ children }: { children: any }) {
  const { userId, isChatBotEnable, chatBotApiKey } = useSelector(userSelector);
  const orgPartyId = localStorage.getItem("organizationPartyId");
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 560px)",
  });
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);
  const { isSuccess, activeOrg, activeOrgStores, activeStore } =
    useSelector(userSelector);
  const [currTab, setCurrTab] = useState(window.location.href.split("/")[4]);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(
    localStorage.getItem("currScreen") === "dashboard"
      ? true
      : localStorage.getItem("isNavOpen") === "true"
      ? true
      : false
  );
  let userInfo: any = localStorage.getItem("setUserInfo");
  if (!userInfo) {
    userInfo = {};
  } else {
    userInfo = JSON.parse(userInfo);
  }
  const history = useHistory();
  let currentSubScreen: any = localStorage.getItem("currentSubScreen");
  const icon = `${isOpen ? "ml-56 duration-300" : "ml-16 duration-300"}`;
  const navOpen = `h-full flex flex-col justify-between w-56 
   fixed top-0 left-0  pt-5 overflow-x-hidden bg-white shadow-2xl duration-300`;
  const navClosed =
    "h-full w-16 fixed top-0  flex flex-col justify-between left-0 bg-white  pt-5 overflow-x-hidden shadow-2xl duration-300";
  const tabSelectedClass =
    "flex items-center cursor-pointer my-3 bg-[#3A6BBF] p-2 rounded-md text-white";
  const tabClass = "flex items-center cursor-pointer ml-1 my-6";
  const selectCurtabClass = "mb-2 text-primary";
  const selectedStoreClass =
    "font-semibold mx-auto rounded-md px-2 py-1.5 bg-gray-200 my-3 cursor-pointer";
  let todayDate: any = new Date();
  let dd = String(todayDate.getDate()).padStart(2, "0");
  let mm = String(todayDate.getMonth() + 1).padStart(2, "0");
  let yyyy = todayDate.getFullYear();
  todayDate = yyyy + "-" + mm + "-" + dd;
  const startingDate = yyyy + "-" + mm + "-01";
  const onLogout = () => {
    dispatch(clearState());
    dispatch(logout());
  };
  const toggleNav = () => {
    if (isOpen) {
      localStorage.setItem("isNavOpen", "false");
    } else {
      localStorage.setItem("isNavOpen", "true");
    }
    dispatch(toggleSidebar());
    setIsOpen((p) => !p);
  };
  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
      history.push("/login");
    }
  }, [isSuccess, dispatch, history]);
  const toggleTab = (name: string) => {
    localStorage.setItem("currScreen", name);
    setCurrTab(name);
  };
  const currentToggleTab = (name: string) => {
    localStorage.setItem("currentSubScreen", name);
  };
  const resetPrevIndex = () => {
    localStorage.removeItem("prevAppliedFilter");
    localStorage.removeItem("currPageIndex");
    localStorage.removeItem("sortedInfoShipmentListing");
    localStorage.removeItem("prevIncomingShipmentListing");
    localStorage.removeItem("passedIndexIncomingShipmentListing");
  };

  useEffect(() => {
    let script: any = document.createElement("script");
    if (isChatBotEnable) {
      if (userId) {
        script.id = "rc-chatbot";
        script.type = "module";
        script.src =
          "https://storage.googleapis.com/rc-public-assets/fe/chatbot/testing-chatbot/chatbot-plugin.js";
        script.setAttribute("organization-party-id", "");
        script.setAttribute("session-id", "");
        script.setAttribute("user-id", "");
        script.setAttribute("is-staging", "true");
        script.setAttribute("app-type", "fullpage");
        console.log("chatBotApiKey", chatBotApiKey);
        script.setAttribute("api-key", chatBotApiKey);
        script.setAttribute("prediction-service", "FulfilmentChatBotService");
        script.setAttribute("icon", "ai-assistant");
        script.setAttribute("height", "420px");
        script.setAttribute("width", "450px");
        script.setAttribute("placement", "right-start");
        script.setAttribute("bottom", "16");
        script.setAttribute("right", "16");
        script.setAttribute("position", "fixed");
        script.setAttribute("element-id", "chatbot");
        const initializedChatbot = () => {
          const moquiSessionToken: any =
            localStorage.getItem("moquiSessionToken");
          setChatbotParams(orgPartyId, userId, moquiSessionToken);
        };
        script.onload = initializedChatbot;

        document.body.appendChild(script);
      }
    }
    return () => {
      // if (script && script.parentNode) {
      //   console.log("script");
      //   document.body?.removeChild(script);
      //   document.body?.appendChild(script);
      //   // script = null;
      // }
      const chatbotEle = document.getElementById("rc-website");
      if (chatbotEle && chatbotEle.parentNode) {
        document.body?.removeChild(chatbotEle);
      }
    };
  }, [userId, orgPartyId, isChatBotEnable, chatBotApiKey]);
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getActiveOrg());
    };
    fetchData();
  }, [dispatch]);

  const content = (
    <div className="p-2">
      {activeOrgStores
        ?.filter((item: any) => item.importEnable !== "N")
        ?.map((item: any) => (
          <p
            onClick={async () => {
              dispatch(setActiveStore(item.productStoreId));
              setIsPopoverVisible((prev: any) => !prev);
            }}
            className={
              activeStore?.productStoreId === item.productStoreId
                ? selectedStoreClass
                : "text-center font-semibold my-3 cursor-pointer"
            }
            key={item?.productStoreId}
          >
            {item.storeName}
          </p>
        ))}
    </div>
  );

  return (
    <>
      <div className={isOpen ? navOpen : navClosed}>
        <div>
          <div className="flex justify-center">
            <Link to="/dashboard">
              {isOpen ? (
                <Image src={logo} preview={false} width={200} />
              ) : (
                <Image src={logo2} preview={false} width={25} />
              )}
            </Link>
          </div>
          <div className="p-3">
            {isOpen && (
              <>
                <div className="w-auto h-10 min-h-full mt-4">
                  <div className="flex flex-row justify-between items-center">
                    <h1 className="font-bold text-base">
                      Hi, {userInfo.name ? userInfo.name : ""}
                    </h1>
                  </div>
                  <h1 className="truncate text-xs mt-2">
                    {userInfo.email ? userInfo.email : ""}
                  </h1>
                </div>
                <div
                  className={`border-gray-500 border border-l-0 border-b-0 border-r-0  ${
                    userInfo?.name?.length > 19 ? "mt-11" : "mt-6"
                  } mr-3`}
                ></div>
              </>
            )}
            {isOpen && (
              <>
                <div className="mt-4">
                  <div className="flex items-center">
                    <h1 className="font-bold text-base">{activeOrg}</h1>
                  </div>
                </div>
                {/* <div className="mr-3">
                  <div className="flex justify-between items-center mt-1">
                    <h1 className=" text-sm">{activeStore?.storeName}</h1>
                    <Popover
                      placement="rightTop"
                      content={content}
                      trigger="click"
                      visible={isPopoverVisible}
                      onVisibleChange={() =>
                        setIsPopoverVisible((prev: any) => !prev)
                      }
                    >
                      <div className="flex  w-max cursor-pointer">
                        <h1
                          className="font-bold text-xs text-secondary-text mr-1.5"
                          style={{ color: "#2C7FB8" }}
                        >
                          SWITCH
                        </h1>
                        <SwapOutlined
                          className="text-secondary-text"
                          style={{ fontSize: "17px", color: "#2C7FB8" }}
                        />
                      </div>
                    </Popover>
                  </div>
                </div> */}
                <div className="border-gray-500 border border-l-0 border-b-0 border-r-0 mt-4 mr-3" />
              </>
            )}
            <div className="mt-4">
              <Link to="/dashboard" className="hover:text-primary">
                <div
                  className={
                    currTab === "dashboard" ? tabSelectedClass : tabClass
                  }
                  onClick={() => {
                    toggleTab("dashboard");
                    // localStorage.removeItem("dashboardFromDate");
                    // localStorage.removeItem("dashboardThruDate");
                    resetPrevIndex();
                  }}
                >
                  <AiOutlineLayout style={{ fontSize: "22px" }} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm `}
                  >
                    Dashboard
                  </p>
                </div>
              </Link>
            </div>
            {/* <div>
              <Link
                to="/reports/orderAgingReport"
                className="hover:text-primary"
              >
                <div
                  onClick={() => {
                    toggleTab("reports");
                    currentToggleTab("orderAgingReport");
                    resetPrevIndex();
                  }}
                  className={
                    currTab === "reports" ? tabSelectedClass : tabClass
                  }
                >
                  <AiOutlineProfile style={{ fontSize: "22px" }} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm`}
                  >
                    Reports
                  </p>
                </div>
              </Link>
              {currTab === "reports" && isOpen && (
                <div className="pl-14">
                  <Link to="/reports/orderAgingReport">
                    <h1
                      onClick={() => {
                        currentToggleTab("orderAgingReport");
                        resetPrevIndex();
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "orderAgingReport"
                          ? "underline"
                          : ""
                      }`}
                    >
                      Order Aging Report
                    </h1>
                  </Link>
                </div>
              )}
            </div> */}
            <div>
              <Link
                to="/inventory/inventoryReport"
                className="hover:text-primary"
              >
                <div
                  onClick={() => {
                    toggleTab("inventory");
                    currentToggleTab("inventoryReport");
                    resetPrevIndex();
                  }}
                  className={
                    currTab === "inventory" ? tabSelectedClass : tabClass
                  }
                >
                  <MdInventory2 style={{ fontSize: "22px" }} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm`}
                  >
                    Inventory
                  </p>
                </div>
              </Link>
              {currTab === "inventory" && isOpen && (
                <div className="pl-14">
                  <Link to="/inventory/inventoryReport">
                    <h1
                      onClick={() => {
                        currentToggleTab("inventoryReport");
                        resetPrevIndex();
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "inventoryReport"
                          ? "underline"
                          : ""
                      }`}
                    >
                      Current Inventory
                    </h1>
                  </Link>
                  <Link to="/inventory/incomingShipment">
                    <h1
                      onClick={() => {
                        currentToggleTab("incomingShipment");
                        resetPrevIndex();
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "incomingShipment"
                          ? "underline"
                          : ""
                      }`}
                    >
                      Incoming POs
                    </h1>
                  </Link>
                  <Link to="/inventory/createIncomingShipment">
                    <h1
                      onClick={() => {
                        currentToggleTab("createIncomingShipment");
                        resetPrevIndex();
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "createIncomingShipment"
                          ? "underline"
                          : ""
                      }`}
                    >
                      Send Inventory
                    </h1>
                  </Link>
                  {/* <Link to="/inventory/onHandSummary">
                    <h1
                      onClick={() => {
                        currentToggleTab("onHandSummary");
                        resetPrevIndex();
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "onHandSummary" ? "underline" : ""
                      }`}
                    >
                      On Hand Summary
                    </h1>
                  </Link> */}
                </div>
              )}
            </div>
            <div>
              <Link
                to={{
                  pathname: `/order/orderListing`,
                  search: `fromDate=${startingDate}&thruDate=${todayDate}`,
                }}
                className="hover:text-primary"
              >
                <div
                  onClick={() => {
                    toggleTab("order");
                    currentToggleTab("orderListing");
                    resetPrevIndex();
                  }}
                  className={currTab === "order" ? tabSelectedClass : tabClass}
                >
                  <FaReceipt style={{ fontSize: "22px" }} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm`}
                  >
                    Orders
                  </p>
                </div>
              </Link>
              {currTab === "order" && isOpen && (
                <div className="pl-14">
                  <Link to="/order/orderListing">
                    <h1
                      onClick={() => {
                        currentToggleTab("orderListing");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "orderListing" ? "underline" : ""
                      }`}
                    >
                      Order Listing
                    </h1>
                  </Link>
                  <Link to="/order/createOrder">
                    <h1
                      onClick={() => {
                        currentToggleTab("createOrder");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "createOrder" ? "underline" : ""
                      }`}
                    >
                      Create Order
                    </h1>
                  </Link>
                  <Link to="/order/importOrder">
                    <h1
                      onClick={() => {
                        currentToggleTab("importOrder");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "importOrder" ? "underline" : ""
                      }`}
                    >
                      Import Orders
                    </h1>
                  </Link>
                  <Link to="/order/errorReport">
                    <h1
                      onClick={() => {
                        currentToggleTab("errorReport");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "errorReport" ? "underline" : ""
                      }`}
                    >
                      Order Error Report
                    </h1>
                  </Link>
                </div>
              )}
            </div>
            <div>
              <Link to="/product/productListing" className="hover:text-primary">
                <div
                  onClick={() => {
                    toggleTab("product");
                    currentToggleTab("productListing");
                    resetPrevIndex();
                  }}
                  className={
                    currTab === "productListing" ? tabSelectedClass : tabClass
                  }
                >
                  <ImCodepen size={24} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm`}
                  >
                    Products
                  </p>
                </div>
              </Link>
              {currTab === "product" && isOpen && (
                <div className="pl-14">
                  <Link to="/product/productListing">
                    <h1
                      onClick={() => {
                        currentToggleTab("productListing");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "productListing" ? "underline" : ""
                      }`}
                    >
                      Product Listing
                    </h1>
                  </Link>
                  <Link to="/product/createProduct">
                    <h1
                      onClick={() => {
                        currentToggleTab("createProduct");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "createProduct" ? "underline" : ""
                      }`}
                    >
                      Create Product
                    </h1>
                  </Link>
                  <Link to="/product/importProduct">
                    <h1
                      onClick={() => {
                        currentToggleTab("importProduct");
                      }}
                      className={`${selectCurtabClass} ${
                        currentSubScreen === "importProduct" ? "underline" : ""
                      }`}
                    >
                      Import Products
                    </h1>
                  </Link>
                </div>
              )}
            </div>
            <div>
              <Link to="/imports" className="hover:text-primary">
                <div
                  className={
                    currTab === "imports" ? tabSelectedClass : tabClass
                  }
                  onClick={() => {
                    toggleTab("imports");
                    resetPrevIndex();
                  }}
                >
                  <BiImport style={{ fontSize: "22px" }} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm `}
                  >
                    Import Status
                  </p>
                </div>
              </Link>
            </div>
            {isChatBotEnable && (
              <div id="chatbot" className={tabClass}>
                <svg
                  fill="none"
                  height="22"
                  viewBox="0 0 64 64"
                  width="22"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                >
                  <linearGradient
                    id="paint0_linear_2813_1920"
                    gradientUnits="userSpaceOnUse"
                    x1="78.644"
                    x2="25.425"
                    y1="74.123"
                    y2="11.863"
                  >
                    <stop offset=".00265844" stop-color="#4646B5"></stop>
                    <stop offset="1" stop-color="#ff6550"></stop>
                  </linearGradient>
                  <path
                    clip-rule="evenodd"
                    d="m2 14c0-6.62742 5.37258-12 12-12h36c6.6274 0 12 5.37258 12 12v28c0 6.6274-5.3726 12-12 12h-10.0925l-6.4023 7.317c-.3798.434-.9285.683-1.5052.683s-1.1254-.249-1.5052-.683l-6.4023-7.317h-10.0925c-6.62742 0-12-5.3726-12-12zm30 0c.9337 0 1.7432.6461 1.9502 1.5565l1.1066 4.8664c.5118 2.2509 2.2694 4.0085 4.5203 4.5203l4.8664 1.1066c.9104.207 1.5565 1.0165 1.5565 1.9502s-.6461 1.7432-1.5565 1.9502l-4.8664 1.1066c-2.2509.5118-4.0085 2.2694-4.5203 4.5203l-1.1066 4.8664c-.207.9104-1.0165 1.5565-1.9502 1.5565s-1.7432-.6461-1.9502-1.5565l-1.1066-4.8664c-.5118-2.2509-2.2694-4.0085-4.5203-4.5203l-4.8664-1.1066c-.9104-.207-1.5565-1.0165-1.5565-1.9502s.6461-1.7432 1.5565-1.9502l4.8664-1.1066c2.2509-.5118 4.0085-2.2694 4.5203-4.5203l1.1066-4.8664c.207-.9104 1.0165-1.5565 1.9502-1.5565z"
                    fill="url(#paint0_linear_2813_1920)"
                    fill-rule="evenodd"
                  ></path>
                </svg>
                <p
                  className={`${
                    !isOpen && "hidden"
                  } ml-2 font-semibold text-sm`}
                >
                  Ask AI (Beta)
                </p>
              </div>
            )}
            <div>
              <Link to="/settings" className="hover:text-primary">
                <div
                  className={
                    currTab === "settings" ? tabSelectedClass : tabClass
                  }
                  onClick={() => {
                    toggleTab("settings");
                  }}
                >
                  <AiOutlineSetting style={{ fontSize: "22px" }} />
                  <p
                    className={`${
                      !isOpen && "hidden"
                    } ml-2 font-semibold text-sm `}
                  >
                    Configurations
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="flex items-center cursor-pointer w-max mt-9 mb-6 ml-5 hover:text-primary"
          onClick={onLogout}
        >
          <Image width={22} src={logout_svg} preview={false} />
          <p className={`${!isOpen && "hidden"} ml-2 font-semibold text-sm`}>
            Log Out
          </p>
        </div>
      </div>
      <div className={icon}>
        <button
          className="fixed top-0 p-3 rounded-br-2xl bg-white shadow-3xl "
          onClick={toggleNav}
        >
          {isOpen ? (
            <AiOutlineMenuFold style={{ fontSize: "22px" }} />
          ) : (
            <AiOutlineMenuUnfold style={{ fontSize: "22px" }} />
          )}
        </button>
        {isOpen && isMobileDevice ? "" : children}
      </div>
    </>
  );
}

export default Sidebar;
