import React, { useEffect, ReactElement, useState } from "react";
import { Form } from "antd";
import { useSelector, useDispatch } from "react-redux";

import Button from "./Button";
import Input from "./Input";
import Loader from "./Loader";
import { userSelector, clearState, changePassword } from "../redux/authSlice";

type PasswordData = {
  oldPassword: string;
  newPassword: string;
  newPasswordVerify: string;
};

function SettingsPassword(): ReactElement {
  const dispatch = useDispatch();
  const [mapForm] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { isSuccess } = useSelector(userSelector);

  const validatePassword = (rule: any, value: any, callback: any) => {
    const res =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        value
      );
    if (!res) {
      callback(
        "Password must be at least 8 characters long, contain an alphabet, a number and a special character!"
      );
    } else {
      callback();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isSuccess, dispatch]);

  return (
    <div className="px-5 pt-6 pb-1 mb-9 w-full sm:w-96 rounded-2xl shadow-sm bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <h1 className="font-bold text-base mb-7">Password</h1>
        <div className="w-11/12 mx-auto">
          <Form
            form={mapForm}
            className="flex flex-col justify-between"
            name="password_form"
            initialValues={{
              remember: true,
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              console.log(JSON.stringify(values));
              const data: PasswordData = {
                oldPassword: values.originalPassword,
                newPassword: values.newPassword,
                newPasswordVerify: values.repeatPassword,
              };
              await dispatch(changePassword(data));
              setIsLoading(false);
            }}
          >
            <div>
              <Form.Item
                name="originalPassword"
                rules={[
                  {
                    required: true,
                    message: "Original Password is required!",
                  },
                ]}
              >
                <Input
                  isPassword={true}
                  inputType="password"
                  title="Original Password"
                  placeholder="********"
                  showDot={true}
                />
              </Form.Item>
              <Form.Item
                name="newPassword"
                rules={[{ validator: validatePassword }]}
              >
                <Input
                  isPassword={true}
                  inputType="password"
                  title="New Password"
                  placeholder="********"
                  showDot={true}
                />
              </Form.Item>
              <Form.Item
                name="repeatPassword"
                dependencies={["newPassword"]}
                rules={[
                  {
                    required: true,
                    message: "Please confirm your password!",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("newPassword") === value) {
                        return Promise.resolve();
                      }

                      return Promise.reject(
                        new Error(
                          "The two passwords that you entered do not match!"
                        )
                      );
                    },
                  }),
                ]}
              >
                <Input
                  isPassword={true}
                  inputType="password"
                  title="Repeat Password"
                  placeholder="********"
                  showDot={true}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="flex justify-end mt-9">
                    <Button
                      widthFull
                      buttonText="Change Password"
                      isDisabled={
                        !mapForm.isFieldsTouched(true) ||
                        !!mapForm
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    />
                  </div>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default SettingsPassword;
