import React from "react";
import { Select } from "antd";
import {
  CaretDownOutlined,
  RightOutlined,
  LeftOutlined,
} from "@ant-design/icons";

function Dropdown({
  placeholder,
  data,
  value,
  onChange,
  searchable,
  title,
  width,
  paddingY,
  isDisabled,
  isPaginated,
  loadProds,
  currIndex,
  maxPageIndex,
  isSearchBoxVisible,
  searchBoxOnChange,
  allowClear = false,
  onClear,
  onSelect,
  onPopupScroll = {},
}: {
  placeholder: string;
  data: Array<any>;
  value?: any;
  onChange?: any;
  searchable?: boolean;
  title?: string;
  width?: string;
  paddingY?: string;
  isDisabled?: any;
  isPaginated?: any;
  loadProds?: any;
  currIndex?: any;
  maxPageIndex?: any;
  isSearchBoxVisible?: boolean;
  searchBoxOnChange?: any;
  allowClear?: any;
  onClear?: any;
  onSelect?: any;
  onPopupScroll?: any;
}) {
  if (searchable) {
    return (
      <div style={{ width }}>
        <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">{title}</h1>
        <Select
          disabled={isDisabled}
          suffixIcon={<CaretDownOutlined style={{ color: "black" }} />}
          className={`border border-gray-400 py-${paddingY} w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
          showSearch
          placeholder={placeholder}
          allowClear={allowClear}
          onClear={onClear}
          onSelect={onSelect}
          value={value}
          onChange={onChange}
          dropdownStyle={{
            borderRadius: "8px",
            borderWidth: "1px",
          }}
          optionFilterProp="children"
          filterOption={(input, option) =>
            option?.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
              0 ||
            option?.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onPopupScroll={onPopupScroll}>
          {data &&
            data.length > 0 &&
            data.map((item) => (
              <Select.Option
                key={`${item.key}_${item.index}`}
                item
                value={item.key}
                className="active:bg-primary">
                {item.value}
              </Select.Option>
            ))}
        </Select>
      </div>
    );
  }
  return (
    <div style={{ width }}>
      <h1 className="font-bold text-xs mb-0.5 sm:mb-1.5">{title}</h1>
      <Select
        disabled={isDisabled}
        suffixIcon={<CaretDownOutlined style={{ color: "black" }} />}
        className={`border border-gray-400 py-${paddingY} w-full bg-white rounded-md focus:outline-none hover:border-primary-light focus:border-primary-light`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        showSearch={isSearchBoxVisible}
        allowClear={allowClear}
        onClear={onClear}
        onSelect={onSelect}
        dropdownStyle={{
          borderRadius: "8px",
          borderWidth: "1px",
        }}
        filterOption={false}
        onSearch={(value) => {
          searchBoxOnChange(value);
        }}
        onPopupScroll={onPopupScroll}
        dropdownRender={(menu) => (
          <div>
            {menu}
            {isPaginated && (
              <div className="p-2" style={{ textAlign: "right" }}>
                <LeftOutlined
                  onClick={() => loadProds("left")}
                  className={`text-lg mr-1 sm:mr-2 ${
                    (maxPageIndex * 1 === 1 ||
                      !currIndex ||
                      currIndex * 1 <= 0 ||
                      (Math.abs(currIndex * 1 - maxPageIndex * 1) > 1 &&
                        currIndex * 1 > maxPageIndex * 1) ||
                      currIndex * 1 === 1) &&
                    "text-gray-300"
                  }`}
                />
                <RightOutlined
                  onClick={() => loadProds("right")}
                  className={`text-lg ml-3 sm:ml-6 ${
                    (!currIndex ||
                      currIndex * 1 <= 0 ||
                      currIndex * 1 >= maxPageIndex) &&
                    "text-gray-300"
                  }`}
                />
              </div>
            )}
          </div>
        )}>
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <Select.Option
              key={`${item.key}_${index}`}
              value={item.key}
              className="active:bg-primary">
              {item.value}
            </Select.Option>
          ))}
      </Select>
    </div>
  );
}

Dropdown.defaultProps = {
  searchable: false,
  title: "",
  width: "100%",
  paddingY: "0.5",
  isDisabled: false,
  isPaginated: false,
  loadProds: () => {},
  isSearchBoxVisible: false,
  searchBoxOnChange: () => {},
};

export default Dropdown;
