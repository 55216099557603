import React from "react";
import { Image } from "antd";
// @ts-ignore
import { useMediaQuery } from "react-responsive";

import logo from "../images/logo.svg";

const Footer = () => {
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 640px)",
  });
  return (
    <div className="w-max mx-auto mt-3">
      <div className="flex flex-wrap items-center mb-3">
        <h1 className="text-xs sm:text-sm font-semibold mr-2">powered by</h1>
        <Image width={isMobileDevice ? 80 : 100} src={logo} preview={false} />
      </div>
    </div>
  );
};

export default Footer;
