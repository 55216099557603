import React, { ReactElement } from "react";

import Footer from "./Footer";

function PageNotFound(props: any): ReactElement {
  return (
    <div className="px-8 pt-12 pb-5 w-full lg:w-7/12 mx-auto rounded-3xl shadow-sm bg-white">
      <div className="w-9/12 mx-auto mb-10">
        <h1 className="text-xl font-medium text-black mb-8 mx-auto text-center">
          {props.screen === "productDetailsScreen" ||
          props.screen === "orderDetailsScreen" ||
          props.screen === "incomingShipmentDetailsScreen"
            ? ""
            : "404 Not Found"}
        </h1>
        <h1 className="text-lg text-black mx-auto text-center">
          {props.screen === "productDetailsScreen"
            ? `Product not found with ID: ${props.id}`
            : props.screen === "orderDetailsScreen"
            ? `Order not found with ID: ${props.id}`
            : props.screen === "incomingShipmentDetailsScreen"
            ? `PO not found with ID: ${props.id}`
            : "The page you are looking for does not exist."}
        </h1>
      </div>
      <Footer />
    </div>
  );
}

export default PageNotFound;
