import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Image } from "antd";
import { useHistory } from "react-router-dom";

import Footer from "../components/Footer";
import logo from "../images/logo.svg";

function ShopifyResponse({
  result,
  accountId,
}: {
  result: any;
  accountId: any;
}): ReactElement {
  const history = useHistory();
  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-lg bg-white">
      <div className="w-max mx-auto">
        <Image
          src={logo}
          width={250}
          preview={false}
          onClick={() => {
            history.push("/");
          }}
        />
      </div>
      <div className="w-9/12 mx-auto mb-6">
        <h1 className="text-lg text-black mt-5 mb-7 mx-auto text-center">
          {result === "true" ? (
            <div>
              <h1>Thanks for installing app.</h1>
              <h1>We've successfully connected to your Shopify store.</h1>
            </div>
          ) : (
            "Invalid Shopify Response."
          )}
        </h1>
        {result !== "true" && (
          <h1 className="text-base text-black mb-3 mx-auto text-center">
            Our executive will contact you soon.
          </h1>
        )}
        <div className="mx-auto w-max">
          <Link
            to="/"
            className="text-xs border-b text-primary border-primary hover:text-primary"
          >
            GO HOME
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ShopifyResponse;
