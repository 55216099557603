/* eslint-disable */
import React, { useState, ReactElement, useEffect } from "react";
import { Form, notification } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";

import {
  checkIntegrationAvailable,
  importData,
  userSelector,
} from "../redux/authSlice";
// @ts-ignore
import { DownloadOutlined } from "@ant-design/icons";

import "../antd.css";
import Button from "./Button";
import Loader from "./Loader";
import UploadFile from "./UploadFile";
import Dropdown from "./Dropdown";

function ImportOrder({
  setShouldRerender,
}: {
  setShouldRerender?: any;
}): ReactElement {
  const [mapForm] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const { activeStore, activeOrgStores } = useSelector(userSelector);
  const [customerShippingInfo, setCustomerShippingInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [csvFileForOrder, setCsvFileForOrder] = useState<any>(null);
  const [importStatus, setImportStatus] = useState("");
  const [importId, setImportId] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadCompletionRate, setUploadCompletionRate] = useState(5);
  const [isIntegrationAvailable, setIsIntegrationAvailable] = useState(false);
  const [showUploadList, setShowUploadList] = useState(false);

  useEffect(() => {
    if (activeStore && activeStore?.productStoreId !== "selectStore") {
      mapForm.setFieldValue("store", activeStore?.productStoreId);
      dispatch(
        checkIntegrationAvailable({
          productStoreId: activeStore?.productStoreId,
          setIsIntegrationAvailable,
          showErrorAlert: false,
        })
      );
    }
  }, [activeStore]);

  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full /*lg:w-11/12*/ rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          <Form
            name="create_order"
            initialValues={customerShippingInfo}
            form={mapForm}
            onFinish={async (values: any) => {
              const { store } = values;

              if (!isIntegrationAvailable) {
                notification["error"]({
                  message: "Error",
                  description: "Integration not available for selected store.",
                });
                return;
              }

              if (!store) {
                notification["error"]({
                  message: "Error",
                  description: "Please select a Store",
                });
                return;
              }
              if (!csvFileForOrder || csvFileForOrder.status === "removed") {
                notification["error"]({
                  message: "Error",
                  description: "Please select a CSV file!",
                });
                return;
              }
              const accountId = localStorage.getItem("accountId");
              if (!accountId) {
                notification["error"]({
                  message: "Error",
                  description: "Accound Id could not be found!",
                });
                return;
              }
              setIsLoading(true);
              let formData = new FormData();
              const organizationPartyId: any = localStorage.getItem(
                "organizationPartyId"
              );
              // const userId: any = localStorage.getItem("userId");
              const partyId: any = localStorage.getItem("partyId");
              formData.append("productStoreId", store);
              formData.append("contentFile", csvFileForOrder);
              formData.append("ownerPartyId", organizationPartyId);
              formData.append("templateId", activeStore?.templateId);
              formData.append("uploadedByPartyId", partyId);

              await dispatch(importData({ data: formData, history }));
              setIsLoading(false);
              setShowUploadList(false);
              setShouldRerender(true);
            }}
          >
            <div className="w-full">
              <Form.Item
                className="w-2/5"
                name="store"
                rules={[
                  {
                    required: true,
                    message: "Please select store",
                  },
                ]}
              >
                <Dropdown
                  placeholder="Select Store"
                  data={activeOrgStores
                    ?.filter((item: any) => item.importEnable !== "N")
                    ?.map((item: any) => {
                      return {
                        key: item.productStoreId,
                        value: item.storeName,
                      };
                    })}
                  onChange={async (value: any) => {
                    // await dispatch(setActiveStore(value));
                    await dispatch(
                      checkIntegrationAvailable({
                        productStoreId: value,
                        setIsIntegrationAvailable,
                      })
                    );
                  }}
                  title="Stores"
                />
              </Form.Item>
              <Form.Item>
                <UploadFile
                  setCsvFile={setCsvFileForOrder}
                  isCloudIconVisible={true}
                  showUploadList={showUploadList}
                  setShowUploadList={setShowUploadList}
                />
              </Form.Item>
              {isUploading && (
                <div className="mb-5">
                  <h1 className="font-semibold text-sm mb-1.5">Uploading...</h1>
                  <ProgressBar
                    completed={uploadCompletionRate}
                    height="7px"
                    borderRadius="0px"
                    bgColor={
                      importStatus === "DataImportFailed" ||
                      importStatus === "DataImportHeaderMatchFailed"
                        ? "#e22426"
                        : "#57bb54"
                    }
                    isLabelVisible={false}
                  />
                  {importStatus === "DataImportSuccess" ? (
                    <h1 className="mt-3">
                      Success! You can review and finalize your order on the
                      next page.
                    </h1>
                  ) : importStatus === "DataImportFailed" ? (
                    <h1 className="mt-3" style={{ color: "#e22426" }}>
                      Something went wrong during upload. Download results to
                      see the errors.
                    </h1>
                  ) : importStatus === "DataImportHeaderMatchFailed" ? (
                    <h1 className="mt-3" style={{ color: "#e22426" }}>
                      Upload Failed.
                    </h1>
                  ) : (
                    ""
                  )}
                </div>
              )}
              <div className="flex">
                <div
                  className="flex items-center mb-3 mt-2 mr-3 cursor-pointer w-max"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      `/fp-static/downloadTemplate?template=ImportOrders_template.xlsx`;
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    DOWNLOAD XLSX TEMPLATE
                  </p>
                </div>
                <div
                  className="flex items-center mb-3 mt-2 cursor-pointer w-max"
                  onClick={() => {
                    if (
                      !activeStore?.fileName ||
                      !activeStore?.productStoreId ||
                      !isIntegrationAvailable
                    )
                      return;
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      `/fp-static/downloadTemplate?template=${activeStore.fileName}`;
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    DOWNLOAD CSV TEMPLATE
                  </p>
                </div>
              </div>
            </div>
            <Form.Item shouldUpdate>
              {() => (
                <div className="mt-5 flex justify-end">
                  <Button widthFull buttonText="Import Order" />
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default ImportOrder;
