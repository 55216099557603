import React from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import { Spin } from "antd";

const Loader = ({
  children,
  isLoading,
  spinner,
  styles,
}: {
  children?: React.ReactNode;
  isLoading: boolean;
  spinner?: any;
  styles?: any;
}) => {
  return (
    <LoadingOverlay active={isLoading} spinner={spinner} styles={styles}>
      {children}
    </LoadingOverlay>
  );
};
Loader.defaultProps = {
  spinner: <Spin />,
};
export default Loader;
