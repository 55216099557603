import React from "react";

type TileData = {
  iconComponent: any;
  value: string;
  title: string;
};

const GridTile = ({ iconComponent, value, title }: TileData) => {
  return (
    <div className="bg-white rounded-xl shadow-lg px-10 xsmall:px-5 py-4 sm:px-7 sm:py-6">
      {iconComponent}
      <h1 className="font-bold text-lg sm:text-2xl w-max">{value}</h1>
      <p className="text-sm sm:text-base text-gray-600 w-max">{title}</p>
    </div>
  );
};

export default GridTile;
