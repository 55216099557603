import React, { ReactElement } from "react";
import queryString from "query-string";

import SetPassword from "../components/SetPassword";

function SetPasswordScreen(props: any): ReactElement {
  const parsed = queryString.parse(props.location.search);
  let email: any = "";
  let oldPassword: any = "";
  let accountId: any = "";
  if (parsed.email) {
    email = parsed.email;
  }
  if (parsed.code) {
    oldPassword = parsed.code;
  }
  if (parsed.accountId) {
    accountId = parsed.accountId;
  }
  email = email.replace(" ", "+");
  return (
    <div className="px-8 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16">
      <SetPassword
        email={email}
        oldPassword={oldPassword}
        accountId={accountId}
      />
    </div>
  );
}

export default SetPasswordScreen;
