import React, { ReactElement } from "react";
import queryString from "query-string";

import UpdatePassword from "../components/UpdatePassword";

function UpdatePasswordScreen(props: any): ReactElement {
  const parsed = queryString.parse(props.location.search);
  let email: any = "";
  let oldPassword: any = "";
  if (parsed.email) {
    email = parsed.email;
  }
  if (parsed.code) {
    oldPassword = parsed.code;
  }
  email = email.replace(" ", "+");
  return (
    <div className="px-10 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16">
      <UpdatePassword email={email} oldPassword={oldPassword} />
    </div>
  );
}

export default UpdatePasswordScreen;
