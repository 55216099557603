export const debounceFn = (fn: Function, delay = 1000) => {
  let timer: any;
  return function (this: any, ...args: any[]) {
    clearTimeout(timer);
    timer = setTimeout(() => fn.apply(this, args), delay);
  };
};

export function setChatbotParams(
  orgPartyId: any,
  userId: any,
  moquiSessionToken: any
) {
  console.log("In Function", orgPartyId, userId, moquiSessionToken);
  const script = document.getElementById("rc-chatbot");
  if (script) {
    console.log("found", moquiSessionToken);
    script.setAttribute("organization-party-id", orgPartyId);
    script.setAttribute("user-id", userId);
    script.setAttribute("session-id", moquiSessionToken);
  } else {
    console.error('Script tag with id "rc-chatbot" not found.');
  }
}
