import React from "react";
import { Upload, notification } from "antd";
import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";

const ALLOWED_TYPES = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // xlsx
  "application/vnd.ms-excel",
  "text/csv",
];

function UploadFile({
  setCsvFile,
  isCloudIconVisible,
  showUploadList = true,
  setShowUploadList = () => {},
}: {
  setCsvFile: any;
  isCloudIconVisible: any;
  showUploadList?: any;
  setShowUploadList?: any;
}) {
  const { Dragger } = Upload;

  const props = {
    name: "file",
    accept: ALLOWED_TYPES.join(","),
    maxCount: 1,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
    },
    beforeUpload: () => false,
    onChange(info: any) {
      const { status, type, size, name } = info.file;
      const fileSize = size / 1024 / 1024 < 1; // 1mb
      console.log("Type", type);
      if (!fileSize) {
        notification["error"]({
          message: "Error",
          description: `${name} file must be less than 1mb.`,
        });
        return;
      }

      if (status === "done") {
        notification["success"]({
          message: "Success",
          description: `${info.file.name} file uploaded successfully.`,
        });
      } else if (status === "error") {
        notification["error"]({
          message: "Error",
          description: `${info.file.name} file upload failed.`,
        });
      }
      if (ALLOWED_TYPES.includes(type)) {
        setCsvFile(info.file);
        setShowUploadList(true);
      } else {
        setCsvFile(null);
        setShowUploadList(false);
        notification["error"]({
          message: "Error",
          description: "Invalid file format, you can only upload CSV file.",
        });
      }
    },
    onRemove: async () => {
      setCsvFile(null);
    },
  };

  return (
    <Dragger
      {...props}
      className={`${!isCloudIconVisible && "pt-5 pb-10 "}`}
      showUploadList={showUploadList}
    >
      {isCloudIconVisible ? (
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
      ) : (
        <InboxOutlined
          style={{
            fontSize: "35px",
            color: "#727272",
            marginTop: "17px",
            marginBottom: "10px",
          }}
        />
      )}
      <p className="ant-upload-text">
        Click or drag file to this area{" "}
        {!isCloudIconVisible && <span>to Upload</span>}
      </p>
      {isCloudIconVisible && (
        <>
          <p className="ant-upload-hint">Supported files: 1 MB or less</p>
          <p className="ant-upload-hint">
            Supported File Formats: *.csv, *.xls
          </p>
        </>
      )}
    </Dragger>
  );
}

export default UploadFile;
