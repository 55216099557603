import React, { useEffect, ReactElement, useState } from "react";
import { Form, FormInstance, Image } from "antd";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "./Button";
import Input from "./Input";
import Loader from "./Loader";
import Footer from "./Footer";
import logo from "../images/logo.svg";
import { clearState, validateUser } from "../redux/authSlice";

function CreateUser({ email, code }: { email: any; code: any }): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const formattedEmail = email.replace(" ", "+");
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const isAuth = localStorage.getItem("loggedInToken");
    if (isAuth) {
      history.push("/");
    }
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]); // eslint-disable-line

  const form = React.createRef<FormInstance>();
  const validatePassword = (rule: any, value: any, callback: any) => {
    const res =
      /^(?=.*[A-Za-z_])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z_\d@$!%*#?&]{8,}$/.test(
        value
      );
    if (!res) {
      callback(
        "Password must be at least 8 characters long, contain an alphabet, a number and a special character!"
      );
    } else {
      callback();
    }
  };
  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-sm bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="w-max mx-auto">
          <Image src={logo} width={250} preview={false} />
        </div>
        <div className="w-full sm:w-9/12 mx-auto">
          <h1 className="text-xl font-medium text-black mb-3 mx-auto text-center">
            Welcome to Fulfillment Plus
            <span className="text-primary-light">!</span>
          </h1>
          <h1 className="mb-4 text-center">User Details</h1>
        </div>
        <div className="w-full sm:w-8/12 mx-auto mb-3">
          <Form
            ref={form}
            name="create_user"
            onFinish={async (values: any) => {
              setIsLoading(true);
              await dispatch(
                validateUser({
                  email: formattedEmail,
                  userFullName: values.fullName,
                  userName: formattedEmail,
                  code,
                  password: values.password,
                  passwordVerify: values.passwordVerify,
                  history,
                })
              );
              setIsLoading(false);
            }}
          >
            <Form.Item
              name="fullName"
              rules={[
                {
                  required: true,
                  message: "Full Name is required!",
                },
              ]}
            >
              <Input
                title="Full Name*"
                placeholder="First Last"
                showDot={true}
              />
            </Form.Item>
            <Form.Item initialValue={formattedEmail} name="email">
              <Input
                title="Email*"
                placeholder="yourname@email.com"
                showDot={false}
                isDisabled={true}
              />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ validator: validatePassword }]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              name="passwordVerify"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Repeat Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item>
              <div className="mx-auto">
                <Button widthFull buttonText="Create Account" />
              </div>
            </Form.Item>
          </Form>
        </div>
        <Footer />
      </Loader>
    </div>
  );
}

export default CreateUser;
