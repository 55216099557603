import React from "react";
import { Image } from "antd";
import dummy_img from "../images/dummy_img.svg";
import { Link } from "react-router-dom";

type OrderDetailItemData = {
  imageSrc: string;
  sku: string;
  name: string;
  variant: string;
  qty: number;
  price: any;
  refundReason?: string;
  item?: any;
};

const OrderDetailItem = ({
  imageSrc,
  sku,
  name,
  variant,
  qty,
  price,
  refundReason,
  item,
}: OrderDetailItemData) => {
  const sumTotal = qty * price;
  return (
    <div className="flex flex-row items-center px-0 sm:px-10 pb-4 border-b border-gray-300">
      <div className="flex flex-row items-center w-3/5">
        <Image
          src={imageSrc ? imageSrc : dummy_img}
          preview={false}
          width={60}
        />
        <div className="ml-6">
          <h1 className="text-xs sm:text-sm font-medium flex">
            SKU:{" "}
            <Link
              to={{
                pathname: `/product/${item?.productId}`,
                state: { productId: item?.productId },
              }}
            >
              <h1 className="cursor-pointer text-primary underline">{sku}</h1>
            </Link>
          </h1>
          {item && item.productId ? (
            // <Link to={`/product/${item.productId}`}>
            <h1 className="text-xs sm:text-sm underline text-primary font-medium my-1.5  hover:text-text-email">
              {name}
            </h1>
          ) : (
            // </Link>
            <h1 className="text-xs sm:text-sm underline text-primary font-medium my-1.5  hover:text-text-email">
              {name}
            </h1>
          )}

          <h1 className="text-xs sm:text-sm font-medium mb-1">{variant}</h1>
          {refundReason && (
            <h1 className="text-xs text-gray-500">
              Reason For Refund : {refundReason}
            </h1>
          )}
        </div>
      </div>
      <h1 className="text-xs sm:text-sm font-medium w-2/5 sm:w-2/6 text-right">
        ${Number(price ?? 0)?.toFixed(2)} x {qty}
      </h1>

      <h1 className="text-xs sm:text-sm font-bold  w-2/5 sm:w-2/6 text-right">
        ${Number(sumTotal ?? 0)?.toFixed(2)}
      </h1>
    </div>
  );
};

OrderDetailItem.defaultProps = {
  refundReason: "",
};

export default OrderDetailItem;
