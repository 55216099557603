import React, { memo, useState } from "react";
import Countdown from "react-countdown";

const CountDown = memo(({ setShouldRerender }: { setShouldRerender: any }) => {
  const [time, setTime] = useState(Date.now() + 15000);
  const [called, setCalled] = useState(0);

  const minutes = 900000; // 15mins
  const seconds = 15000; // 15secs
  const noOfCalls = minutes / seconds; // 60 times

  const onFinish = () => {
    setTime(Date.now() + 15000);
    setShouldRerender(true);
  };

  // Renderer callback with condition
  const renderer = ({
    seconds,
    completed,
  }: {
    seconds: any;
    completed: any;
  }) => {
    if (completed) {
      if (called <= noOfCalls) {
        setCalled((prev) => prev + 1);
        onFinish();
      }
      return;
    } else {
      return (
        <p className="ml-1 sm:ml-2 text-xs font-bold text-primary mr-2 mb-5">
          Auto refreshing in {seconds}
        </p>
      );
    }
  };

  return <Countdown date={time} renderer={renderer} key={time} />;
});

export default CountDown;
