import React, { useEffect, ReactElement, useState } from "react";
import { Form, Image } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "./Button";
import Input from "./Input";
import { updatePassword, userSelector, clearState } from "../redux/authSlice";
import Footer from "./Footer";
import logo from "../images/logo.svg";
import Loader from "../components/Loader";

type ResetPasswordData = {
  emailAddress: string;
  password: string;
  oldPassword: string;
  passwordVerify: string;
  isReset: boolean;
  history: any;
};

function ResetPassword({
  email,
  oldPassword,
}: {
  email: string;
  oldPassword: any;
}): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { isSuccess } = useSelector(userSelector);
  const validatePassword = (rule: any, value: any, callback: any) => {
    const res =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        value
      );
    if (!res) {
      callback(
        "Password must be at least 8 characters long, contain an alphabet, a number and a special character!"
      );
    } else {
      callback();
    }
  };
  useEffect(() => {
    const isAuth = localStorage.getItem("loggedInToken");
    if (isAuth) {
      history.push("/");
    }
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(clearState());
    }
  }, [isSuccess, dispatch]);

  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-lg bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="w-max mx-auto">
          <div className="w-max mx-auto">
            <Image src={logo} width={250} preview={false} />
          </div>
        </div>
        <div className="w-full sm:w-9/12 mx-auto">
          <h1 className="text-xl font-medium text-black mb-3 mx-auto text-center">
            Update Password
            <span className="text-primary">!</span>
          </h1>
          <h1 className="mb-4 text-center">Please create a new password</h1>
        </div>
        <div className="w-full sm:w-8/12 mx-auto">
          <Form
            name="reset_password"
            initialValues={{
              remember: true,
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              const data: ResetPasswordData = {
                emailAddress: email,
                oldPassword,
                password: values.password,
                passwordVerify: values.passwordVerify,
                isReset: true,
                history,
              };
              await dispatch(updatePassword(data));
              setIsLoading(false);
            }}
          >
            <Form.Item
              name="password"
              rules={[{ validator: validatePassword }]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item
              name="passwordVerify"
              dependencies={["password"]}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input
                isPassword={true}
                inputType="password"
                title="Repeat Password"
                placeholder="********"
                showDot={true}
              />
            </Form.Item>
            <Form.Item>
              <div className="mx-auto">
                <Button widthFull buttonText="Submit" />
              </div>
            </Form.Item>
          </Form>
        </div>
        <Footer />
      </Loader>
    </div>
  );
}

export default ResetPassword;
