import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import Login from "../components/Login";

function LoginScreen() {
  const history = useHistory();
  useEffect(() => {
    const isAuth = localStorage.getItem("loggedInToken");
    let redirectTo = "/";
    const currPath = localStorage.getItem("currentPath");
    if (currPath) {
      redirectTo = currPath;
    }
    if (isAuth) {
      history.push(redirectTo);
    }
  }, [history]);
  return (
    <div className="px-8 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16">
      <Login />
    </div>
  );
}

export default LoginScreen;
