import { ReactElement, useState } from "react";
import { useHistory } from "react-router-dom";

import Sidebar from "../components/Sidebar";
import ProductDetail from "../components/ProductDetail";
import PageNotFoundScreen from "./PageNotFoundScreen";

function ProductDetailsScreen(props: any): ReactElement {
  const currentScreen = "Product Listing";
  const subScrName = "Product Details";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();
  const [isIdValid, setIsIdValid] = useState(true);
  const handleIdValidity = (isValid: boolean) => {
    setIsIdValid(isValid);
  };
  return (
    <Sidebar>
      <div className="px-4 sm:px-8 lg:px-16 pt-11 lg:pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            {" "}
            Product Details
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary ml-1"
              onClick={() => {
                history.push("/product/productListing");
              }}
            >
              {currentScreen}
            </h1>
            <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
              <span className="text-primary mx-1">{arrow}</span>
              {subScrName}
            </h1>
          </div>
        </div>
        <div className="">
          {isIdValid ? (
            <ProductDetail
              id={props.match.params.id}
              handleIdValidity={handleIdValidity}
            />
          ) : (
            <PageNotFoundScreen
              screen={"productDetailsScreen"}
              id={props.match.params.id}
            />
          )}
        </div>
      </div>
    </Sidebar>
  );
}

export default ProductDetailsScreen;
