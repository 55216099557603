import React, { useState, ReactElement } from "react";

import ResetPasswordEmail from "../components/ResetPasswordEmail";
import ResetEmailSuccess from "../components/ResetEmailSuccess";

function ResetPasswordScreen(): ReactElement {
  const [nextPage, setNextPage] = useState(false);
  return (
    <div className="px-6 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16">
      {!nextPage ? (
        <ResetPasswordEmail setNextPage={setNextPage} />
      ) : (
        <ResetEmailSuccess />
      )}
    </div>
  );
}

export default ResetPasswordScreen;
