import React from "react";
import ErrorReport from "../components/ErrorReport";
import Sidebar from "../components/Sidebar";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const ErrorReportScreen = (props: any) => {
  const parsed = queryString.parse(props.location.search);
  let fromDate: any = "";
  let thruDate: any = "";
  let queryStr: any = "";
  if (parsed.fromDate) {
    fromDate = parsed.fromDate;
  }
  if (parsed.thruDate) {
    thruDate = parsed.thruDate;
  }
  if (parsed.queryString) {
    queryStr = parsed.queryString;
  }
  const currentScreen = "Report";
  const parentScreen = "Fulfillment Plus";
  const subScrName = "Order Error Report";
  const arrow = ">";
  const history = useHistory();
  return (
    <div>
      <Sidebar>
        <div className="px-8 sm:px-16 pt-3 pb-16">
          <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
            <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
              {" "}
              Order Error Report
            </h1>
            <div className="mt-2 flex flex-row">
              <h1
                className="font-semibold text-xs cursor-pointer hover:text-primary"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                {parentScreen}
                {!(localStorage.getItem("fromDashboardScreen") === "true") && (
                  <span className="text-primary mx-1">{arrow}</span>
                )}
              </h1>
              {!(localStorage.getItem("fromDashboardScreen") === "true") && (
                <h1 className="font-semibold text-xs cursor-pointer hover:primary-bg ml-1">
                  {currentScreen}
                </h1>
              )}
              <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
                <span className="text-primary mx-1">{arrow}</span>
                {subScrName}
              </h1>
            </div>
          </div>
          <div className="">
            <ErrorReport
              fromDate={fromDate}
              thruDate={thruDate}
              queryString={queryStr}
            />
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default ErrorReportScreen;
