import React, { ReactElement } from "react";
import queryString from "query-string";

import CreateUser from "../components/CreateUser";

function CreateUserScreen(props: any): ReactElement {
  const parsed = queryString.parse(props.location.search);
  let email: any = "";
  let code: any = "";
  if (parsed.email) {
    email = parsed.email;
  }
  if (parsed.code) {
    code = parsed.code;
  }

  return (
    <div className="px-8 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16">
      <CreateUser email={email} code={code} />
    </div>
  );
}

export default CreateUserScreen;
