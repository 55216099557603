import Footer from "./Footer";
import React, { ReactElement } from "react";
import { Link } from "react-router-dom";
import { Image } from "antd";

import logo from "../images/logo.svg";

function ResetEmailSuccess(): ReactElement {
  const resetPasswordEmail = localStorage.getItem("resetPasswordEmail");
  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-lg bg-white">
      <div className="w-max mx-auto">
        <Image src={logo} width={250} preview={false} />
      </div>
      <div className="w-9/12 mx-auto mb-10 mt-10">
        <h1 className="text-3xl font-semibold text-black mb-3 mx-auto text-center">
          Email sent to<span className="text-primary">:</span>
        </h1>
        {resetPasswordEmail && (
          <h1 className="text-lg text-black mb-3 mx-auto text-center">
            {resetPasswordEmail}
          </h1>
        )}
        <h1 className="text-base text-black mb-3 mx-auto text-center">
          Please check your email for instructions.
        </h1>
      </div>
      <div className="mx-auto mb-5 flex flex-wrap justify-center items-center">
        <div>
          <Link
            to="/login"
            className="text-xs border-b text-primary border-primary hover:text-primary"
          >
            GO BACK
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default ResetEmailSuccess;
