import React from "react";
import { useHistory } from "react-router";

import IncomingShipmentListing from "../components/IncomingShipmentListing";
import Sidebar from "../components/Sidebar";

function IncomingShipmentListingScreen() {
  const history = useHistory();
  const currentScreen = "Incoming Shipment Listing";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            {" "}
            Incoming Shipments
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className=" mx-1">{arrow}</span>
            </h1>
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary ml-1"
              onClick={() => {
                history.push("/inventory/incomingshipment");
              }}
            >
              {currentScreen}
            </h1>
          </div>
        </div>
        <div className="">
          <IncomingShipmentListing />
        </div>
      </div>
    </Sidebar>
  );
}

export default IncomingShipmentListingScreen;
