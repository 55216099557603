import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useEffect, memo } from "react";

const SupersetEmbedding = memo(
  ({
    id,
    supersetDomain,
    guestToken,
  }: {
    id: any;
    supersetDomain: any;
    guestToken: any;
  }) => {
    useEffect(() => {
      const containerEle = document.getElementById("container");
      if (containerEle) {
        embedDashboard({
          id, // given by the Superset embedding UI
          supersetDomain: `https://${supersetDomain}`,
          mountPoint: containerEle, // html element in which iframe render
          fetchGuestToken: async () => guestToken, // guest_token
          dashboardUiConfig: { hideTitle: true },
        });
      }

      const screensize = document.querySelectorAll("#container > iframe");
      if (screensize.length > 0) {
        screensize[0].setAttribute("style", "min-width: 100%; height: 1080px");
        screensize[0].setAttribute("id", "myiframe");
        const iframeEle: any = document.getElementById("myiframe");
        const bodyTag = iframeEle?.contentWindow?.document?.body;
        if (bodyTag) {
          bodyTag.style.border = "none";
        }
      }
      return () => {
        if (screensize.length > 0 && screensize[0].hasAttribute("style")) {
          screensize[0].removeAttribute("style");
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, supersetDomain, guestToken]);
    return <div id="container"></div>;
  }
);

export default SupersetEmbedding;
