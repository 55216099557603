import React, { useEffect, ReactElement, useState } from "react";
import { Form, Image } from "antd";
import { useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import Button from "./Button";
import Input from "./Input";
import { clearState, resetPasswordEmail } from "../redux/authSlice";
import Footer from "./Footer";
import logo from "../images/logo.svg";
import Loader from "../components/Loader";

function ResetPasswordEmail({
  setNextPage,
}: {
  setNextPage: Function;
}): ReactElement {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const isAuth = localStorage.getItem("loggedInToken");
    if (isAuth) {
      history.push("/");
    }
    return () => {
      dispatch(clearState());
    };
  }, [dispatch, history]);

  return (
    <div className="px-8 pt-8 pb-1 w-full lg:w-7/12 mx-auto rounded-3xl shadow-lg bg-white">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div className="w-max mx-auto">
          <Image src={logo} width={250} preview={false} />
        </div>
        <div className="w-9/12 mx-auto mb-6">
          <h1 className="text-lg sm:text-2xl font-medium text-black mx-auto text-center mt-2">
            Reset Password
          </h1>
        </div>
        <div className="w-full sm:w-8/12 mx-auto">
          <Form
            name="normal_login"
            initialValues={{
              remember: true,
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              const emailAddress = values.email;
              await dispatch(resetPasswordEmail({ emailAddress, setNextPage }));
              setIsLoading(false);
            }}
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: "Email is required",
                },
                {
                  type: "email",
                  message: "Email should be a valid one!",
                },
              ]}
            >
              <Input
                title="Email"
                placeholder="yourname@email.com"
                showDot={true}
              />
            </Form.Item>
            <Form.Item>
              <div className="mx-auto">
                <Button widthFull buttonText="Send Email" />
              </div>
            </Form.Item>
          </Form>
          <div className="mx-auto mb-5 flex flex-wrap justify-center items-center">
            <div>
              <Link
                to="/login"
                className="text-xs border-b text-primary border-primary hover:text-primary"
              >
                GO BACK
              </Link>
            </div>
          </div>
        </div>
        <Footer />
      </Loader>
    </div>
  );
}

export default ResetPasswordEmail;
