import { HashRouter as Router, Route, Switch } from "react-router-dom";
import axios from "axios";
import { notification } from "antd";

import DashBoardScreen from "./screens/DashBoardScreen";
import IncomingShipmentDetailsScreen from "./screens/IncomingShipmentDetailsScreen";
import IncomingShipmentListingScreen from "./screens/IncomingShipmentListingScreen";
import LoginScreen from "./screens/LoginScreen";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import OrderListingScreen from "./screens/OrderListingScreen";
import PageNotFoundScreen from "./screens/PageNotFoundScreen";
import PrivateRoute from "./screens/PrivateRoute";
import OrderAgingReportScreen from "./screens/OrderAgingReportScreen";
import InventoryReportScreen from "./screens/InventoryReportScreen";
import SettingScreen from "./screens/SettingScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import UpdatePasswordScreen from "./screens/UpdatePasswordScreen";
import ProductListingScreen from "./screens/ProductListingScreen";
import ProductDetailsScreen from "./screens/ProductDetailScreen";
import InventoryShipScreen from "./screens/InventoryShipScreen";
import CreateOrderScreen from "./screens/CreateOrderScreen";
import CreateUserScreen from "./screens/CreateUserScreen";
import ShopifyResponseScreen from "./screens/ShopifyResponseScreen";
import SetPasswordScreen from "./screens/SetPasswordScreen";
import OrderListingDashboard from "./screens/OrderListingDashboard";
import ProductImportScreen from "./screens/ProductImportScreen";
import ImportScreen from "./screens/ImportScreen";
import OrderImportScreen from "./screens/OrderImportScreen";
import CreateProductScreen from "./screens/CreateProductScreen";
import ErrorReportScreen from "./screens/ErrorReportScreen";
import { useDispatch } from "react-redux";
import { logout } from "./redux/authSlice";
// import OnHandSummaryReportScreen from "./screens/OnHandSummaryReportScreen";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;
axios.defaults.withCredentials = true;

window.onunload = function () {
  localStorage.removeItem("dashboardFromDate");
  localStorage.removeItem("dashboardThruDate");
  localStorage.removeItem("activeFilter");
};

function App() {
  const dispatch = useDispatch();
  const clearUserId: any = async () => {
    await dispatch(logout());
  };
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      // let currUrl = originalRequest.url.split("/");
      // currUrl = currUrl[currUrl.length - 1];
      if (error.response && error.response.status === 401) {
        let errorMsg = "";
        if (typeof error.response.data === "string") {
          errorMsg = error.response.data;
        } else if (error.response.data.errors) {
          errorMsg = error.response.data.errors;
        }
        if (
          errorMsg.includes("Session token does not match") ||
          errorMsg.includes("Session token required")
        ) {
          // Renew session token
          return axios.get("/rest/s1/fp/moquiSessionToken").then((resp) => {
            if (resp.status === 200) {
              localStorage.setItem(
                "moquiSessionToken",
                resp.data.moquiSessionToken
              );
              originalRequest.headers["moquiSessionToken"] =
                resp.data.moquiSessionToken;
              return axios(originalRequest);
            }
          });
        } else if (errorMsg.includes("User must be logged in")) {
          // User is not logged in, redirect to login page
          localStorage.clear();
          window.location.replace("/#/login");
        }
      } else if (error.response && error.response.status === 403) {
        let errorMsg = "";
        if (typeof error.response.data === "string") {
          errorMsg = error.response.data;
        } else if (error.response.data.errors) {
          errorMsg = error.response.data.errors;
        }
        if (errorMsg.includes("is not authorized for View")) {
          // User is not logged in, redirect to login page
          localStorage.clear();
          clearUserId();
          window.location.replace("/#/login");
        }
      } else if (
        error?.response &&
        error?.response?.status === 400 &&
        window?.location?.hash?.includes("/login")
      ) {
        const errorMessages = error?.response?.data?.errors || [];
        const loginErrorMessages = [
          "Email address isn't in our system!",
          "Password incorrect for username",
        ];
        if (
          loginErrorMessages?.some((msg: any) => errorMessages?.includes(msg))
        ) {
          notification["error"]({
            message: "Error",
            description: "Incorrect login credentials",
          });
        }
      } else if (error.message === "Network Error") {
        notification["error"]({
          message: "Error",
          description: "Network Error",
        });
        return;
      } else {
        const errMsg =
          error.response && error.response.data && error.response.data.errors
            ? error.response.data.errors
            : "Connection Refused";
        notification["error"]({
          message: "Error",
          description: errMsg,
        });
        return;
      }
    }
  );
  return (
    <Router>
      <Switch>
        <Route path="/dashboard" component={DashBoardScreen} />
        <Route path="/login" component={LoginScreen} />
        <Route path="/order/orderListing" component={OrderListingScreen} />
        <Route path="/order/errorReport" component={ErrorReportScreen} />

        <Route path="/order/orderDashboard" component={OrderListingDashboard} />

        <Route path="/order/createOrder" component={CreateOrderScreen} />
        <Route path="/order/importOrder" component={OrderImportScreen} />
        <Route path="/order/:id" component={OrderDetailScreen} />
        <Route path="/resetPassword" component={ResetPasswordScreen} />
        <Route path="/updatePassword" component={UpdatePasswordScreen} />
        <Route
          path="/product/productListing"
          component={ProductListingScreen}
        />
        <Route path="/product/importProduct" component={ProductImportScreen} />
        <Route path="/product/createProduct" component={CreateProductScreen} />
        <Route path="/product/:id" component={ProductDetailsScreen} />

        <Route
          path="/inventory/incomingShipment"
          component={IncomingShipmentListingScreen}
        />
        <Route
          path="/inventory/createIncomingShipment"
          component={InventoryShipScreen}
        />
        <Route
          path="/inventory/inventoryReport"
          component={InventoryReportScreen}
        />
        {/* <Route
          path="/inventory/onHandSummary"
          component={OnHandSummaryReportScreen}
        /> */}
        <Route
          path="/inventory/:id"
          component={IncomingShipmentDetailsScreen}
        />
        <Route
          path="/reports/orderAgingReport"
          component={OrderAgingReportScreen}
        />
        <Route path="/settings" component={SettingScreen} />
        <Route path="/setPassword" component={SetPasswordScreen} />
        <Route path="/shopifyResponse" component={ShopifyResponseScreen} />
        <Route path="/createUser" component={CreateUserScreen} />
        <Route path="/imports" component={ImportScreen} />
        <PrivateRoute exact component={DashBoardScreen} path="/" />
        <Route path="*" component={PageNotFoundScreen} />
      </Switch>
    </Router>
  );
}

export default App;
