import React from "react";
import Sidebar from "../components/Sidebar";
import DasboardMain from "../components/DashboardMain";

function OrderListingDashboard() {
  return (
    <>
      <Sidebar>
        <div className="px-8 sm:px-16 pt-3 pb-16">
          <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
            <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg"> Orders</h1>
          </div>
          <DasboardMain />
        </div>
      </Sidebar>
    </>
  );
}

export default OrderListingDashboard;
