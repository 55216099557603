import React from "react";
import { Upload, notification } from "antd";
import { CloudUploadOutlined, InboxOutlined } from "@ant-design/icons";

function UploadImageFile({
  setFile,
  isCloudIconVisible,
  showUploadList = true,
  setShowUploadList = () => {},
}: {
  setFile: any;
  isCloudIconVisible: any;
  showUploadList?: any;
  setShowUploadList?: any;
}) {
  const { Dragger } = Upload;

  const props = {
    name: "file",
    accept: "image/*",
    maxCount: 1,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
    },
    beforeUpload: () => false,
    onChange(info: any) {
      const { status, size } = info.file;
      const fileSize = size / 1024 / 1024 < 0.5; // 500kb
      if (!fileSize) {
        notification["error"]({
          message: "Error",
          description: `${info.file.name} file must be less than 500kb.`,
        });
        return;
      }
      setFile(info.file);
      setShowUploadList(true);
      if (status === "done") {
        notification["success"]({
          message: "Success",
          description: `${info.file.name} file uploaded successfully.`,
        });
      } else if (status === "error") {
        notification["error"]({
          message: "Error",
          description: `${info.file.name} file upload failed.`,
        });
      }
    },
    onRemove: async () => {
      setFile(null);
    },
  };

  return (
    <Dragger
      {...props}
      className={`${!isCloudIconVisible && "pt-5 pb-10 "}`}
      showUploadList={showUploadList}
    >
      {isCloudIconVisible ? (
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
      ) : (
        <InboxOutlined
          style={{
            fontSize: "35px",
            color: "#727272",
            marginTop: "17px",
            marginBottom: "10px",
          }}
        />
      )}
      <p className="ant-upload-text">
        Click or drag file to this area{" "}
        {!isCloudIconVisible && <span>to Upload</span>}
      </p>
      {isCloudIconVisible && (
        <p className="ant-upload-hint">
          Supported files: 500kb or less, PNG, JPEG, JPG
        </p>
      )}
    </Dragger>
  );
}

export default UploadImageFile;
