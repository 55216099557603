import React from "react";
import { Upload, notification } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";

const ALLOWED_TYPES = [
  "image/jpeg",
  "image/png",
  "image/jpg",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "application/vnd.oasis.opendocument.text",
  "application/rtf",
  "application/pdf",
  "text/plain",
  "text/csv",
];

function UploadDocument({
  setFile,
  isCloudIconVisible,
  showUploadList = true,
  setShowUploadList = () => {},
  multiple = false,
  fileList = [],
}: {
  setFile: any;
  isCloudIconVisible: any;
  showUploadList?: any;
  setShowUploadList?: any;
  multiple?: boolean;
  fileList?: Array<any>;
}) {
  const { Dragger } = Upload;

  const props = {
    name: "file",
    accept: ALLOWED_TYPES.join(","),
    maxCount: multiple ? 10 : 1,
    multiple,
    fileList,
    progress: {
      strokeColor: {
        "0%": "#108ee9",
        "100%": "#87d068",
      },
      strokeWidth: 3,
      format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
    },
    beforeUpload: () => false,
    onChange(info: any) {
      const { status, type, size, name } = info.file;
      const fileSize = size / 1024 / 1024 <= 20; // 20mb
      if (!fileSize) {
        return notificationError(`${name} file must be 20 MB or less.`);
      }

      if (status === "done") {
        return notificationSuccess(`${name} file uploaded successfully.`);
      } else if (status === "error") {
        return notificationError(`${name} file upload failed.`);
      }

      if (ALLOWED_TYPES.includes(type)) {
        setFile(info.fileList.map((item: any) => item));
        setShowUploadList(true);
      } else {
        setFile(null);
        setShowUploadList(false);
        return notificationError("Invalid file format.");
      }
    },
    onRemove: () => setFile([]),
  };

  const notificationSuccess = (message: string) => {
    notification.success({
      message: "Success",
      description: message,
    });
  };

  const notificationError = (message: string) => {
    notification.error({
      message: "Error",
      description: message,
    });
  };

  return (
    <Dragger
      {...props}
      className={`${!isCloudIconVisible && "pt-5 pb-10 "}`}
      showUploadList={showUploadList}
    >
      {isCloudIconVisible && (
        <p className="ant-upload-drag-icon">
          <CloudUploadOutlined />
        </p>
      )}
      <p className="ant-upload-text">
        Click or drag file to this area{" "}
        {!isCloudIconVisible && <span>to Upload</span>}
      </p>
      {isCloudIconVisible && (
        <>
          <p className="ant-upload-hint">Supported File Size: 20 MB or less</p>
          <p className="ant-upload-hint">
            Supported File Formats: *.img, *.csv, *.pdf, *.txt, *.xls, *.docx
          </p>
        </>
      )}
    </Dragger>
  );
}

export default UploadDocument;
