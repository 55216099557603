/* eslint-disable */
import React, { useEffect, useState, ReactElement } from "react";
import { Form, FormInstance, Popover, Tooltip, notification } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import {
  createProduct,
  getEnumTypeList,
  getProductCategoryList,
  getProductStoresList,
  userSelector,
} from "../redux/authSlice";

import "../antd.css";
import Button from "../components/Button";
import Loader from "../components/Loader";
import Input from "../components/Input";
import Dropdown from "../components/Dropdown";

function CreateProduct({
  facilitiesList,
  selectedFacility,
  setSelectedFacility,
}: {
  facilitiesList: any;
  selectedFacility: any;
  setSelectedFacility: any;
}): ReactElement {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [categoryListData, setCategoryListData] = useState([]);
  const [productTypeListData, setProductTypeListData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const { productStoresList } = useSelector(userSelector);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(getProductStoresList());
      await dispatch(
        getEnumTypeList({
          setEnumType: setProductTypeListData,
          enumTypeId: "ProductIdentificationType",
        })
      );
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (productTypeListData?.length > 0) {
      form.setFieldValue("idType", "PidtSku");
    }
  }, [productTypeListData]);

  const content = (
    <ol>
      <li>1. Select Asset(Good) for finished good</li>
      <li>
        2. Select Asset(Good) for Kit products that are pre-assembled in the
        warehouse
      </li>
      <li>
        3. Select Kit Assembly for Kit products that are assembled by the picker
        on the fly
      </li>
    </ol>
  );

  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        // spinner={
        //   <Image
        //     className="animate-spin"
        //     width={25}
        //     src={SpinnerSvg}
        //     preview={false}
        //   />
        // }
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "",
          }),
        }}
      >
        {/* <div>
          <p className="text-black font-light mb-4">
            We still need some inventory from you before we can go live.
          </p>
          <p className="text-black font-light mb-8">
            Please be sure to submit this form when you send inventory to us so
            that our warehouse team doesn't get mad at us. Seriously, they're
            the best, let's make it easier on them.
          </p>
        </div> */}
        <div>
          <Form
            name="create_product"
            form={form}
            initialValues={{
              chargeShipping: "Y",
              description: "",
              descriptionLong: "",
              idType: "",
              idValue: "",
              productCategoryId: "",
              productName: "",
              productTypeEnumId: "PtAsset",
              productStoreId: "",
              returnable: "Y",
            }}
            onFinish={async (values: any) => {
              setIsLoading(true);
              const data = { ...values };
              await dispatch(createProduct({ data, history }));
              setIsLoading(false);
            }}
          >
            <div className="w-full">
              <div className="sm:flex">
                <Form.Item
                  name="productName"
                  className="sm:mr-4 flex-1"
                  rules={[
                    { required: true, message: "This is required field!" },
                  ]}
                >
                  <Input
                    title="Product Name"
                    placeholder="Product Name"
                    showDot={true}
                  />
                </Form.Item>
                <Popover placement="rightTop" content={content}>
                  <Form.Item
                    className="flex-1"
                    name="productTypeEnumId"
                    rules={[{ required: true, message: "Select an option!" }]}
                  >
                    <Dropdown
                      title="Product Type"
                      placeholder="Select One"
                      data={[
                        {
                          value: "Asset (Good)",
                          key: "PtAsset",
                        },
                        {
                          value: "Pick Assembly",
                          key: "PtPickAssembly",
                        },
                      ]}
                      searchable={false}
                    />
                  </Form.Item>
                </Popover>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="productStoreId"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Product Store"
                    placeholder="Select One"
                    data={productStoresList}
                    searchable={false}
                    onChange={async (value: any) => {
                      if (value) {
                        form.setFieldValue("productCategoryId", "");
                        setIsLoading(true);
                        await dispatch(
                          getProductCategoryList({
                            setCategoryListData,
                            productStoreId: value,
                          })
                        );
                        setIsLoading(false);
                      }
                    }}
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="productCategoryId"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Category"
                    placeholder="Select One"
                    data={categoryListData?.map((cat: any) => {
                      return {
                        key: cat.value,
                        value: cat.label,
                      };
                    })}
                    searchable={false}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item className="flex-1" name="description">
                  <Input
                    title="Short Description"
                    placeholder="Short Description"
                    showDot={true}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="returnable"
                  rules={[{ required: false, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Returnable"
                    placeholder="Select one"
                    data={[
                      {
                        value: "Yes",
                        key: "Y",
                      },
                      {
                        value: "No",
                        key: "N",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="chargeShipping"
                  rules={[{ required: false, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Charge Shipping"
                    placeholder="Select one"
                    data={[
                      {
                        value: "Yes",
                        key: "Y",
                      },
                      {
                        value: "No",
                        key: "N",
                      },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="idType"
                  rules={[{ required: true, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="ID Type"
                    placeholder="Select one"
                    data={productTypeListData}
                  />
                </Form.Item>
                <Form.Item
                  className="flex-1"
                  name="idValue"
                  rules={[
                    { required: true, message: "This is required field!" },
                  ]}
                >
                  <Input
                    title="ID Value"
                    placeholder="ID Value"
                    showDot={true}
                  />
                </Form.Item>
              </div>

              {/* <div className="sm:flex">
                <Form.Item
                  className="sm:mr-4 flex-1"
                  name="isVirtual"
                  rules={[{ required: false, message: "Select an option!" }]}
                >
                  <Dropdown
                    title="Is Virtual"
                    placeholder="Is Virtual"
                    data={[
                      {
                        value: "Yes",
                        key: "Y",
                      },
                      {
                        value: "No",
                        key: "N",
                      },
                    ]}
                    onChange={() => {}}
                    searchable={false}
                  />
                </Form.Item>
                <Form.Item className="sm:mr-4 flex-1" name="email" rules={[]}>
                  <Input title="Color" placeholder="Color" showDot={true} />
                </Form.Item>
                <Form.Item className="flex-1" name="size">
                  <Input title="Size" placeholder="Size" showDot={true} />
                </Form.Item>
              </div> */}
              <Form.Item name="descriptionLong">
                <Input
                  multiline={true}
                  title="Description"
                  placeholder=""
                  showDot={false}
                />
              </Form.Item>
            </div>
            <Form.Item>
              <div className="w-full mt-5 flex justify-end">
                <Button widthFull={true} buttonText="Create" />
              </div>
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default CreateProduct;
