import { ReactElement } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import Sidebar from "../components/Sidebar";
import OrderListing from "../components/OrderListing";

function IncomingShipmentScreen(props: any): ReactElement {
  const parsed = queryString.parse(props.location.search);
  let fromDate: any = "";
  let thruDate: any = "";
  let statusId: any = "";
  let queryStr: any = "";
  let facilityId: any = "";
  let isReturnedOrders: any = "";

  if (parsed.fromDate) {
    fromDate = parsed.fromDate;
  }
  if (parsed.thruDate) {
    thruDate = parsed.thruDate;
  }
  if (parsed.statusId) {
    statusId = parsed.statusId;
  }
  if (parsed.queryString) {
    queryStr = parsed.queryString;
  }
  if (parsed.facilityId) {
    facilityId = parsed.facilityId;
  }
  if (parsed.isReturnedOrders) {
    isReturnedOrders = parsed.isReturnedOrders;
  }
  const currentScreen = "Order Aging Report";
  const subScrName = "Order Listing";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            {" "}
            Order Listing
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              {!(localStorage.getItem("fromDashboardScreen") === "true") && (
                <span className="text-primary mx-1">{arrow}</span>
              )}
            </h1>
            {!(localStorage.getItem("fromDashboardScreen") === "true") && (
              <h1
                className="font-semibold text-xs cursor-pointer hover:primary-bg ml-1"
                onClick={() => {
                  history.push("/reports/incomingshipment");
                }}
              >
                {currentScreen}
              </h1>
            )}
            <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
              <span className="text-primary mx-1">{arrow}</span>
              {subScrName}
            </h1>
          </div>
        </div>
        <div className="">
          <OrderListing
            fromDate={fromDate}
            thruDate={thruDate}
            facilityId={facilityId}
            statusId={statusId}
            queryString={queryStr}
            isReturnedOrders={isReturnedOrders}
            onlyShipped={statusId === "OrderCompleted" ? true : false}
          />
        </div>
      </div>
    </Sidebar>
  );
}

export default IncomingShipmentScreen;
