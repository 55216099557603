/* eslint-disable */
import React, { useEffect, useState, ReactElement } from "react";
import { Form, FormInstance, notification } from "antd";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";

import {
  userSelector,
  checkWholeSalesOrderItem,
  importData,
} from "../redux/authSlice";
// @ts-ignore
import { DownloadOutlined } from "@ant-design/icons";

import "../antd.css";
import Button from "./Button";
import Loader from "./Loader";
import UploadFile from "./UploadFile";

function ImportProduct({
  setShouldRerender,
}: {
  setShouldRerender?: any;
}): ReactElement {
  const [mapForm] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [customerShippingInfo] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [csvFileForOrder, setCsvFileForOrder] = useState<any>(null);
  const [importStatus, setImportStatus] = useState("");
  const [, setImportId] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [uploadCompletionRate, setUploadCompletionRate] = useState(5);
  const [showUploadList, setShowUploadList] = useState(false);

  const form = React.createRef<FormInstance>();

  useEffect(() => {
    const fetchData = async () => {
      if (csvFileForOrder !== null) {
        setIsUploading(true);
        setUploadCompletionRate(5);
        setImportStatus("");
        let formDataForOrder = new FormData();
        formDataForOrder.append("contentFile", csvFileForOrder);
        await dispatch(
          checkWholeSalesOrderItem({
            data: formDataForOrder,
            setImportStatus,
            setImportId,
            setUploadCompletionRate,
          })
        );
      }
    };
    // fetchData();
  }, [csvFileForOrder]); // eslint-disable-line

  return (
    <div className="mb-8 lg:mb-0 px-8 pt-8 pb-4 w-full lg:w-11/12 rounded-3xl shadow-sm bg-white col-span-2">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          <Form
            name="create_order"
            initialValues={customerShippingInfo}
            ref={form}
            form={mapForm}
            onFinish={async (values: any) => {
              if (!csvFileForOrder || csvFileForOrder.status === "removed") {
                notification["error"]({
                  message: "Error",
                  description: "Please select a CSV file!",
                });
                return;
              }
              const accountId = localStorage.getItem("accountId");
              if (!accountId) {
                notification["error"]({
                  message: "Error",
                  description: "Accound Id could not be found!",
                });
                return;
              }
              setIsLoading(true);
              let formData = new FormData();
              const organizationPartyId: any = localStorage.getItem(
                "organizationPartyId"
              );
              // const userId: any = localStorage.getItem("userId");
              const partyId: any = localStorage.getItem("partyId");
              formData.append("contentFile", csvFileForOrder);
              formData.append("ownerPartyId", organizationPartyId);
              formData.append("templateId", "Product_Data_Import");
              formData.append("uploadedByPartyId", partyId);

              await dispatch(importData({ data: formData, history }));
              setIsLoading(false);
              setShowUploadList(false);
              setShouldRerender(true);
            }}
          >
            <div className="w-full">
              <Form.Item>
                <UploadFile
                  setCsvFile={setCsvFileForOrder}
                  isCloudIconVisible={true}
                  showUploadList={showUploadList}
                  setShowUploadList={setShowUploadList}
                />
              </Form.Item>
              {isUploading && (
                <div className="mb-5">
                  <h1 className="font-semibold text-sm mb-1.5">Uploading...</h1>
                  <ProgressBar
                    completed={uploadCompletionRate}
                    height="7px"
                    borderRadius="0px"
                    bgColor={
                      importStatus === "DataImportFailed" ||
                      importStatus === "DataImportHeaderMatchFailed"
                        ? "#e22426"
                        : "#57bb54"
                    }
                    isLabelVisible={false}
                  />
                  {importStatus === "DataImportSuccess" ? (
                    <h1 className="mt-3">
                      Success! You can review and finalize your order on the
                      next page.
                    </h1>
                  ) : importStatus === "DataImportFailed" ? (
                    <h1 className="mt-3" style={{ color: "#e22426" }}>
                      Something went wrong during upload. Download results to
                      see the errors.
                    </h1>
                  ) : importStatus === "DataImportHeaderMatchFailed" ? (
                    <h1 className="mt-3" style={{ color: "#e22426" }}>
                      Upload Failed.
                    </h1>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {/* {importStatus === "DataImportFailed" && (
                <div
                  className="flex items-center mb-6 cursor-pointer w-max"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      `/rest/s1/fp/order/downloadOrderItemResultFile?importId=${importId}`;
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    VIEW ERRORS
                  </p>
                </div>
              )} */}
              <div className="flex">
                <div
                  className="flex items-center mb-3 mt-2 mr-3 cursor-pointer w-max"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      "/fp-static/downloadTemplate?template=ImportProducts_template.xlsx";
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    DOWNLOAD XLSX TEMPLATE
                  </p>
                </div>
                <div
                  className="flex items-center mb-3 mt-2 cursor-pointer w-max"
                  onClick={() => {
                    window.location.href =
                      process.env.REACT_APP_API_BASE_URL +
                      "/fp-static/downloadProductTemplate";
                  }}
                >
                  <DownloadOutlined style={{ fontSize: "18px" }} />
                  <p className="ml-1 sm:ml-2 text-xs font-semibold text-primary">
                    DOWNLOAD CSV TEMPLATE
                  </p>
                </div>
              </div>
            </div>
            <Form.Item shouldUpdate>
              {() => (
                <div className="mt-5 flex justify-end">
                  <Button
                    widthFull
                    buttonText="Import Product"
                    // isDisabled={
                    //   importStatus !== "DataImportSuccess" ||
                    //   csvFileForOrder === null ||
                    //   !!mapForm
                    //     .getFieldsError()
                    //     .filter(({ errors }) => errors.length).length
                    // }
                  />
                </div>
              )}
            </Form.Item>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default ImportProduct;
