/* eslint-disable */
import React, { useEffect, useState } from "react";
import Table from "./Table";
import {
  channelIntegration,
  getErrorReport,
  getProductStoresList,
  importErrorMsg,
  resetErrorMsg,
  userSelector,
} from "../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "./Button";
import { AiOutlineFilter } from "react-icons/ai";
import Loader from "./Loader";
import DatePicker from "./DatePicker";
import { BsDash } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import Input from "./Input";
const FilterSearchBar = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}: {
  setSelectedKeys: any;
  selectedKeys: any;
  confirm: any;
  clearFilters: any;
}) => {
  return (
    <div style={{ padding: 8 }}>
      <div>
        <Input
          title=""
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e: any) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              confirm();
            }
          }}
          onBlur={confirm}
        />
        <div className="mt-3 flex flex-row items-center justify-between">
          <button
            onClick={clearFilters}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
          >
            Reset
          </button>
          <button
            onClick={() => {
              document
                .getElementsByClassName("ant-dropdown")[0]
                .classList.add("ant-dropdown-hidden");
              confirm();
            }}
            className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};
const ErrorReport = ({
  fromDate,
  thruDate,
  queryString,
}: {
  fromDate: any;
  thruDate: any;
  queryString: any;
}) => {
  const dispatch = useDispatch();
  const [errorReportList, setErrorReportList] = useState([]);
  const { productStoresList, activeOrgStores } = useSelector(userSelector);
  const [checkErrorReportRes, setCheckErrorReportRes] = useState<any>([]);
  const [checkErrorMsg, setCheckErrorMsg] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState<any>({});
  const [activeAppliedFilters, setActiveAppliedFilters] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorListPageIndex, setErrorListPageIndex] = useState<any>("1");
  const [errorListPageMaxIndex, setErrorListPageMaxIndex] = useState<any>("");
  const [recordCount, setRecordCount] = useState("");
  const [pageSize, setPageSize] = useState<any>();
  const [datePickerFrom, setDatePickerFrom] = useState<any>(
    fromDate
      ? moment(fromDate, "YYYY-MM-DD")
      : localStorage.getItem("orderImportReportFromDate")
      ? moment(localStorage.getItem("orderImportReportFromDate"), "YYYY-MM-DD")
      : ""
  );
  const [selectedFromDate, setSelectedFromDate] = useState(
    fromDate
      ? fromDate
      : localStorage.getItem("orderImportReportFromDate")
      ? localStorage.getItem("orderImportReportFromDate")
      : ""
  );
  const [selectedThruDate, setSelectedThruDate] = useState(
    thruDate
      ? thruDate
      : localStorage.getItem("orderImportReportThruDate")
      ? localStorage.getItem("orderImportReportThruDate")
      : ""
  );
  const [datePickerThru, setDatePickerThru] = useState<any>(
    thruDate
      ? moment(thruDate, "YYYY-MM-DD")
      : localStorage.getItem("orderImportReportThruDate")
      ? moment(localStorage.getItem("orderImportReportThruDate"), "YYYY-MM-DD")
      : ""
  );
  const isMobileDevice = useMediaQuery({
    query: "(max-device-width: 638px)",
  });
  useEffect(() => {
    fetchData();
    return () => {
      localStorage.removeItem("passedIndexErrorReportListing");
      setSelectedFromDate("");
      setSelectedThruDate("");
      setDatePickerFrom("");
      setDatePickerThru("");
    };
  }, []);
  const fetchData = async () => {
    setIsLoading(true);
    await dispatch(
      getErrorReport({
        setErrorReportList,
        pageIndex: 0,
        setRecordCount,
        setErrorListPageMaxIndex,
      })
    );
    await dispatch(getProductStoresList());
    setIsLoading(false);
  };

  const nextSetOfProds = async (
    pageIndex: any,
    appliedFilters?: any,
    pageSize?: any
  ) => {
    setIsLoading(true);
    await dispatch(
      getErrorReport({
        setErrorReportList,
        pageIndex,
        pageSize,
        setRecordCount,
        setErrorListPageMaxIndex,
        appliedFilters,
        placedDate_from: selectedFromDate,
        placedDate_thru: selectedThruDate,
      })
    );
    setIsLoading(false);
  };

  const loadNextProds = (index: any, pageSize?: any) => {
    if (!errorListPageIndex || errorListPageIndex * 1 === 0) {
      return;
    }
    if (errorListPageIndex * 1 > errorListPageMaxIndex) {
      return;
    }
    const passedIndex: any = index * 1 - 1;
    setErrorListPageIndex(index);
    localStorage.setItem("passedIndexErrorReportListing", passedIndex);

    // let orderByFields = sortData;
    if (pageSize > 10) {
      setPageSize(pageSize);
    }
    nextSetOfProds(passedIndex, activeAppliedFilters, pageSize);
  };

  function onChange(pagination: any, filters: any, sorter: any, extra: any) {
    let appliedFilters = "";
    if (filters?.productStoreId) {
      if (!appliedFilters) {
        appliedFilters += `productStoreId=${filters?.productStoreId?.join(
          ","
        )}`;
      } else {
        appliedFilters += `&productStoreId=${filters?.productStoreId?.join(
          ","
        )}`;
      }
    }
    if (filters.displayOrderId) {
      if (!appliedFilters) {
        appliedFilters += `displayId=${filters.displayOrderId}`;
      } else {
        appliedFilters += `&displayId=${filters.displayOrderId}`;
      }
    }
    if (filters.externalOrderId) {
      if (!appliedFilters) {
        appliedFilters += `externalId=${filters.externalOrderId}`;
      } else {
        appliedFilters += `&externalId=${filters.externalOrderId}`;
      }
    }
    setFilteredInfo(filters);
    setActiveAppliedFilters(appliedFilters);
    if (appliedFilters) {
      nextSetOfProds(0, appliedFilters, pageSize);
    } else {
      nextSetOfProds(0, "", pageSize);
    }
  }
  const ReportColumn = [
    {
      title: "Display ID",
      dataIndex: "displayOrderId",
      render: (displayOrderId: any) => {
        return (
          <div>
            <h1>{displayOrderId}</h1>
          </div>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.displayOrderId || null,
      onFilter: (value: any, record: any) =>
        record?.displayOrderId?.includes(value),
      filterIcon: () => {
        return filteredInfo.displayOrderId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
    },
    {
      title: "External ID",
      dataIndex: "externalOrderId",
      render: (externalOrderId: any) => {
        return (
          <div>
            <h1>{externalOrderId}</h1>
          </div>
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <FilterSearchBar
          setSelectedKeys={setSelectedKeys}
          selectedKeys={selectedKeys}
          confirm={confirm}
          clearFilters={clearFilters}
        />
      ),
      filteredValue: filteredInfo?.externalOrderId || null,
      onFilter: (value: any, record: any) =>
        record?.externalOrderId?.includes(value),
      filterIcon: () => {
        return filteredInfo.externalOrderId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
    },
    {
      title: "Store",
      dataIndex: "productStoreId",
      filters: activeOrgStores?.map((item: any) => {
        return {
          value: item?.productStoreId,
          text: item?.storeName,
        };
      }),
      filterIcon: () => {
        return filteredInfo.productStoreId ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filteredValue: filteredInfo?.productStoreId || null,
      render: (productStoreId: any) => {
        return (
          <div>
            <h1>
              {
                productStoresList?.find(
                  (item: any) => productStoreId === item?.key
                )?.value
              }
            </h1>
          </div>
        );
      },
    },
    {
      title: "Error Message",
      dataIndex: "errorMessage",
      render: (errorMessage: any) => {
        return (
          <div>
            <h1>{errorMessage}</h1>
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "createdDate",
      render: (createdDate: any) => {
        return (
          <div>
            <h1>
              {createdDate ? moment(createdDate)?.format("YYYY-MM-DD") : ""}
            </h1>
          </div>
        );
      },
      filterIcon: () => {
        return filteredInfo.placedDate ? (
          <AiOutlineFilter size={17} color="#093479" />
        ) : (
          <AiOutlineFilter size={17} />
        );
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: {
        setSelectedKeys: any;
        selectedKeys: any;
        confirm: any;
        clearFilters: any;
      }) => (
        <div style={{ padding: 8 }}>
          <div>
            <div className="sm:flex sm:flex-row sm:items-center">
              <DatePicker
                title="From"
                value={datePickerFrom}
                onChange={(date: any, dateString: any) => {
                  setDatePickerFrom(date);
                  setSelectedFromDate(dateString);
                  localStorage.setItem("orderImportReportFromDate", dateString);
                }}
              />
              {!isMobileDevice && <BsDash className="mt-5 mx-0.5" />}
              <div className="mt-3 sm:mt-0">
                <DatePicker
                  title="Thru"
                  value={datePickerThru}
                  onChange={(date: any, dateString: any) => {
                    setDatePickerThru(date);
                    setSelectedThruDate(dateString);
                    localStorage.setItem(
                      "orderImportReportThruDate",
                      dateString
                    );
                  }}
                />
              </div>
            </div>
            <div className="mt-3 flex flex-row items-center justify-between">
              <button
                onClick={async () => {
                  setIsLoading(true);
                  await dispatch(
                    getErrorReport({
                      setErrorReportList,
                      pageIndex: 0,
                      pageSize,
                      setRecordCount,
                      setErrorListPageMaxIndex,
                      queryString,
                      appliedFilters: activeAppliedFilters,
                      placedDate_from: fromDate ? fromDate : "",
                      placedDate_thru: thruDate ? thruDate : "",
                    })
                  );
                  setSelectedFromDate("");
                  setSelectedThruDate("");
                  setDatePickerFrom("");
                  setDatePickerThru("");
                  localStorage.removeItem("orderImportReportThruDate");
                  localStorage.removeItem("orderImportReportFromDate");
                  localStorage.removeItem("passedIndexErrorReportListing");
                  filteredInfo["placedDate"] = null;
                  clearFilters();
                  setIsLoading(false);
                }}
                className="px-5 text-xs sm:px-3 sm:text-sm bg-transparent focus:outline-none border border-black text-black font-semibold py-1 rounded-lg"
              >
                Reset
              </button>
              <button
                onClick={async () => {
                  if (!selectedFromDate && !selectedThruDate) {
                    return;
                  }
                  setIsLoading(true);
                  document
                    .getElementsByClassName("ant-dropdown")[0]
                    .classList.add("ant-dropdown-hidden");
                  await dispatch(
                    getErrorReport({
                      setErrorReportList,
                      pageIndex: 0,
                      pageSize,
                      setRecordCount,
                      setErrorListPageMaxIndex,
                      queryString,
                      appliedFilters: activeAppliedFilters,
                      placedDate_from: selectedFromDate,
                      placedDate_thru: selectedThruDate,
                    })
                  );
                  filteredInfo["placedDate"] = "placedDate";
                  setIsLoading(false);
                }}
                className="px-5 text-xs sm:px-3 sm:text-sm bg-primary focus:outline-none border text-white font-semibold py-1 rounded-lg"
              >
                Filter
              </button>
            </div>
          </div>
        </div>
      ),
    },
    {
      title: "Re-Import",
      dataIndex: "systemMessageId",
      render: (systemMessageId: any, data: any) => {
        return (
          <div>
            <h1>
              <Button
                onClick={async () => {
                  setIsLoading(true);
                  await dispatch(
                    channelIntegration({
                      productStoreId: data?.productStoreId,
                      displayOrderId: data?.displayOrderId,
                      externalOrderId: data?.externalOrderId,
                      integrationId: data?.integrationId,
                    })
                  );
                  await dispatch(
                    getErrorReport({
                      setErrorReportList,
                      pageIndex: localStorage.getItem(
                        "passedIndexErrorReportListing"
                      )
                        ? localStorage.getItem("passedIndexErrorReportListing")
                        : 0,
                      pageSize,
                      setRecordCount,
                      setErrorListPageMaxIndex,
                      appliedFilters: activeAppliedFilters,
                      placedDate_from: selectedFromDate,
                      placedDate_thru: selectedThruDate,
                    })
                  );
                  setIsLoading(false);
                }}
                buttonText="Re-Import"
              />
            </h1>
          </div>
        );
      },
    },
  ];
  return (
    <div className="h-screen">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "",
          }),
        }}
      >
        <div className="bg-white p-5 pb-0.5 rounded-lg shadow-sm">
          <Table
            columns={ReportColumn}
            dataSource={errorReportList}
            rowKey=""
            isPaginated
            onChange={onChange}
            loadNextProds={loadNextProds}
            recordCount={recordCount}
            isServerRendered={recordCount ? true : false}
          />
        </div>
      </Loader>
    </div>
  );
};

export default ErrorReport;
