import React, { ReactElement } from "react";
import queryString from "query-string";

import ShopifyResponse from "../components/ShopifyResponse";

function ShopifyResponseScreen(props: any): ReactElement {
  const parsed = queryString.parse(props.location.search);
  let result: any = "";
  let accountId: any = "";
  if (parsed.result) {
    result = parsed.result;
  }
  if (parsed.accountId) {
    accountId = parsed.accountId;
  }
  return (
    <div className="px-10 sm:px-16 md:px-24 lg:px-40 py-6 md:py-14 lg:py-16 bg-neu-bg bg-cover">
      <ShopifyResponse result={result} accountId={accountId} />
    </div>
  );
}

export default ShopifyResponseScreen;
