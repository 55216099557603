import React, { ReactElement } from "react";
import { Input as AntInput, Popover } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

type InputType = {
  value?: any;
  onChange?: any;
  placeholder: string;
  title: string;
  suffix?: string;
  hasInfoIcon?: boolean;
  showDot?: boolean;
  inputType?: string;
  multiline?: boolean;
  isPassword?: boolean;
  isDisabled?: boolean;
  onKeyDown?: any;
  autoFocus?: any;
  onClick?: any;
  clearInput?: Function;
  onBlur?: any;
};

function Input({
  value,
  onChange,
  placeholder,
  title,
  suffix,
  hasInfoIcon,
  showDot,
  inputType,
  multiline,
  isPassword,
  isDisabled,
  onKeyDown,
  autoFocus,
  onClick,
  clearInput,
  onBlur,
}: InputType): ReactElement {
  const suffixClass =
    "suffix-inp border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary focus:border-primary";
  const reqFieldClassPassword =
    "custom-inp border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary focus:border-primary text-primary font-mono";
  const reqFieldClass =
    "custom-inp border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary focus:border-primary";
  const normalFieldClass =
    "border border-gray-400 px-2 py-2 w-full rounded-md focus:outline-none hover:border-primary focus:border-primary";
  return (
    <div>
      <div className="flex items-center mb-0.5 sm:mb-1.5">
        <h1 className="font-bold text-xs">{title}</h1>
        {hasInfoIcon && (
          <Popover
            content={
              <p className="text-xs">
                This product is not available in all sales channels. Try this
                for next steps.
              </p>
            }
            placement="rightTop"
          >
            <InfoCircleFilled style={{ fontSize: "15px", marginLeft: "7px" }} />
          </Popover>
        )}
      </div>
      <div className="flex relative">
        {multiline ? (
          <textarea
            onWheelCapture={(e: any) => {
              e.target.blur();
            }}
            disabled={isDisabled}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            rows={4}
            cols={60}
            className={showDot ? reqFieldClass : normalFieldClass}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            onClick={onClick}
          />
        ) : (
          <AntInput
            onWheelCapture={(e: any) => {
              e.target.blur();
            }}
            onBlur={onBlur}
            disabled={isDisabled}
            type={inputType}
            placeholder={placeholder}
            value={value}
            onChange={onChange}
            suffix={suffix}
            onKeyDown={onKeyDown}
            autoFocus={autoFocus}
            onClick={onClick}
            className={
              showDot
                ? isPassword
                  ? reqFieldClassPassword
                  : reqFieldClass
                : suffix !== ""
                ? suffixClass
                : normalFieldClass
            }
          />
        )}
        {showDot && <div className="reqDot"></div>}
      </div>
    </div>
  );
}

Input.defaultProps = {
  showDot: true,
  inputType: "text",
  multiline: false,
  hasInfoIcon: false,
  isPassword: false,
  suffix: "",
  isDisabled: false,
  onKeyDown: () => {},
  autoFocus: false,
  onBlur: () => {},
};

export default Input;
