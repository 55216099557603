import React, { useEffect } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import queryString from "query-string";

import { getShopifyAppConfig } from "../redux/authSlice";

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  location,
  ...rest
}) => {
  const dispatch = useDispatch();
  let locationObj: any = location;
  const parsed = queryString.parse(locationObj.search);
  let isShopExists = "";
  let searchParams = window?.location?.search?.split("&");
  searchParams?.forEach((item: any) => {
    if (item?.includes("shop")) {
      let splitItem = item?.split("=")?.[1];
      splitItem = splitItem?.split(".")?.[0];
      isShopExists = splitItem;
    }
  });
  useEffect(() => {
    const fetchData = async () => {
      let parsedShopName: any = parsed.shop;
      parsedShopName = parsedShopName?.split(".")?.[0];
      await dispatch(
        getShopifyAppConfig({
          passedStoreName: parsed.shop
            ? parsedShopName
            : isShopExists
            ? isShopExists
            : "",
          redirectToUrl: true,
        })
      );
    };
    if (parsed.shop || isShopExists) {
      fetchData();
    }
  }, []); // eslint-disable-line
  if (!Component) return null;
  if (parsed.shop || isShopExists) {
    //window.location.href = `${process.env.REACT_APP_API_BASE_URL}/processShopifyAppResponse?code=${parsed.code}&hmac=${parsed.hmac}&host=${parsed.host}&shop=${parsed.shop}&state=${parsed.state}&timestamp=${parsed.timestamp}`
    // window.location.href = `https://${storeName}.myshopify.com/admin/oauth/authorize?client_id=${apiKey}&scope=${scope}&redirect_uri=${redirectionUrl}&state=${organizationPartyId}`
    return <></>;
  } else {
    return (
      <Route
        {...rest}
        render={(props) =>
          localStorage.getItem("loggedInToken") ? (
            // <Component {...props} />
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
};

export default PrivateRoute;
