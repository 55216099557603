import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Input from "./Input";
import Button from "./Button";
import Loader from "./Loader";
import { Form, FormInstance } from "antd";
import { MdDelete } from "react-icons/md";
import {
  createUser,
  deactivateUserAccount,
  getListOfUser,
} from "../redux/authSlice";

function UserAccounts() {
  const [mapForm] = Form.useForm();
  const dispatch = useDispatch();
  const [listOfUser, setListOfUser] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isScreenUpdated, setIsScreenUpdated] = useState<any>(false);
  const form = React.createRef<FormInstance>();

  let userInfo: any = localStorage.getItem("setUserInfo");
  if (!userInfo) {
    userInfo = {};
  } else {
    userInfo = JSON.parse(userInfo);
  }

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(getListOfUser(setListOfUser));
      setIsLoading(false);
    };
    fetchData();
  }, [isScreenUpdated]); // eslint-disable-line

  return (
    <div className="px-5 pt-6 pb-1 mr-9 mb-9 w-full sm:w-96 rounded-2xl shadow-sm bg-white flex flex-col justify-between">
      <Loader
        isLoading={isLoading}
        styles={{
          overlay: (base: any) => ({
            ...base,
            backgroundColor: "white",
          }),
        }}
      >
        <div>
          <h1 className="font-bold text-base mb-6">User Accounts</h1>
          <div className="mb-7">
            <h1 className="font-bold text-base mb-1">Owner</h1>
            {listOfUser?.map((item: any) => {
              if (item.userGroupId === "FPAppUser") {
                return null;
              }
              return <h1 className="mt-1.5">{item.emailAddress}</h1>;
            })}
          </div>
          <h1 className="font-bold text-base mb-1">Users</h1>
          <div className="h-40 overflow-x-hidden scrollbar-thin">
            {listOfUser?.map((item: any) => {
              if (item.userGroupId === "FPAppCustomer") {
                return null;
              }
              return (
                <div className="flex flex-row items-center justify-between m-2 ml-1">
                  <h1>{item.emailAddress}</h1>
                  <MdDelete
                    className="text-black cursor-pointer"
                    size={20}
                    onClick={async () => {
                      setIsLoading(true);
                      await dispatch(
                        deactivateUserAccount({
                          userId: item.userId,
                          setIsScreenUpdated,
                        })
                      );
                      setIsLoading(false);
                    }}
                  />
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <Form
            ref={form}
            form={mapForm}
            className="flex flex-col justify-between"
            name="user_accounts"
            onFinish={async (values: any) => {
              setIsLoading(true);
              await dispatch(
                createUser({ emailAddress: values.email, setIsScreenUpdated })
              );
              mapForm.resetFields();
              setIsLoading(false);
            }}
          >
            <div>
              <Form.Item
                className="mt-5"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Email is required!",
                  },
                  {
                    type: "email",
                    message: "Email should be a valid one!",
                  },
                ]}
              >
                <Input
                  title="Add new user"
                  placeholder="Email Address"
                  showDot={true}
                />
              </Form.Item>
            </div>
            <div>
              <Form.Item shouldUpdate>
                {() => (
                  <div className="flex flex-col items-end justify-end">
                    <Button
                      widthFull
                      buttonText="Invite User"
                      isDisabled={
                        !mapForm.isFieldsTouched(true) ||
                        mapForm
                          .getFieldsError()
                          .filter(({ errors }) => errors.length).length
                      }
                    />
                  </div>
                )}
              </Form.Item>
            </div>
          </Form>
        </div>
      </Loader>
    </div>
  );
}

export default UserAccounts;
