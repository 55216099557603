import React, { useState, useEffect, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Sidebar from "../components/Sidebar";
import { getFacilityList } from "../redux/authSlice";
import Loader from "../components/Loader";
import CreateProduct from "../components/CreateProduct";

function CreateProductScreen(): ReactElement {
  const [facilityList, setFacilityList] = useState<any>([]);
  const [selectedFacility, setSelectedFacility] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const currentScreen = "Product";
  const subScrName = "Create Product";
  const arrow = ">";
  const parentScreen = "Fulfillment Plus";
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        getFacilityList({
          setFacilityList,
          setSelectedFacility,
          fromInventory: true,
        })
      );
      setIsLoading(false);
    };
    fetchData();
  }, []); // eslint-disable-line
  return (
    <Sidebar>
      <div className="px-8 sm:px-16 pt-3 pb-16">
        <div className="flex flex-row justify-between border-b-2 border-gray-300 mb-6 mt-2">
          <h1 className="mt-10 sm:mt-0 mb-3 font-bold text-lg">
            {/* We're excited to work with you! */}
          </h1>
          <div className="mt-2 flex flex-row">
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              {parentScreen}
              <span className="text-primary mx-1">{arrow}</span>
            </h1>
            <h1
              className="font-semibold text-xs cursor-pointer hover:text-primary ml-1"
              onClick={() => {
                history.push("/product/createProduct");
              }}
            >
              {currentScreen}
            </h1>
            <h1 className="font-semibold text-xs cursor-pointer hover:text-primary ml-1">
              <span className="text-primary mx-1">{arrow}</span>
              {subScrName}
            </h1>
          </div>
        </div>
        {selectedFacility && (
          <Loader
            isLoading={isLoading}
            styles={{
              overlay: (base: any) => ({
                ...base,
                backgroundColor: "white",
              }),
            }}
          >
            <div className="grid grid-cols-1 gap-0 lg:grid-cols-3">
              <CreateProduct
                facilitiesList={facilityList}
                selectedFacility={selectedFacility}
                setSelectedFacility={setSelectedFacility}
              />
              {/* 
              <div>
                <InventoryShipInfo facilityId={selectedFacility} />
              </div> */}
            </div>
          </Loader>
        )}
      </div>
    </Sidebar>
  );
}

export default CreateProductScreen;
